import React from 'react';
import MobileNavBarPlain from '../generalComponents/MobileNavBarPlain';
import AddressCard from './AddressCard';
import Selector from './Selector';

const EditAddressCard =(props)=>{

    return(
        <>
          <div className="editAddressCard" value={props.address.id} onClick={props.select}>
  <div className="half">
      <div className="flexStart">
      <div className="body1 blue1 button">Edit</div>
      </div>
      <div className="flexEnd">
      <svg value={props.address.id} onClick={props.popUpDelete} className="button" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M5.73438 1.95312H5.5625C5.65703 1.95312 5.73438 1.87578 5.73438 1.78125V1.95312H12.2656V1.78125C12.2656 1.87578 12.343 1.95312 12.4375 1.95312H12.2656V3.5H13.8125V1.78125C13.8125 1.02285 13.1959 0.40625 12.4375 0.40625H5.5625C4.8041 0.40625 4.1875 1.02285 4.1875 1.78125V3.5H5.73438V1.95312ZM16.5625 3.5H1.4375C1.05723 3.5 0.75 3.80723 0.75 4.1875V4.875C0.75 4.96953 0.827344 5.04688 0.921875 5.04688H2.21953L2.7502 16.2832C2.78457 17.0158 3.39043 17.5938 4.12305 17.5938H13.877C14.6117 17.5938 15.2154 17.018 15.2498 16.2832L15.7805 5.04688H17.0781C17.1727 5.04688 17.25 4.96953 17.25 4.875V4.1875C17.25 3.80723 16.9428 3.5 16.5625 3.5ZM13.7115 16.0469H4.28848L3.76855 5.04688H14.2314L13.7115 16.0469Z" fill="#767676"/>
</svg></div></div>

              <div className="wide t2">
                  <div className="body1">{props.address.address1}</div>
              </div>
              <div className="wide t1">
                  <div className="body1">{props.address.city}</div>
              </div><div className="wide t1">
                  <div className="body1">{props.address.state} {props.address.zipcode}</div>
              </div>
              <div className="wide t1">
                  <div className="body1">United States</div>
              </div>
              <div className="wide t1">
                  <div className="body1">({'5614184047'.substring(0,3)})xxxxxx{'5614184047'.substring(8,10)}</div>
              </div>

</div>
        </>
    )
}

export default function(props){
    const popUpDelete=(e)=>{
        // console.log("test 1",e.target.id)
        try {
            e.stopPropagation()
            e.preventDefault()
        } catch (error) {
            
        }
        let value= e.target.value
        let target = e.target
        while (true) {
            if(target.getAttribute("value")){
                break
                //
            }else{
                target=target.parentElement
            }
        }
        console.log("mucho antes",e.target)
        let neoModal={...props.userPack.modal}
        neoModal.open=true
        neoModal.type="deleteConfirmation"
        neoModal.addressId=target.getAttribute("value")
        delete neoModal.cardId
        props.userPack.methods.stateHandling('modal',neoModal)
    }
    return(
        <>
        <MobileNavBarPlain title="Address book"/>
        <div className="mainBody">
                <div className="row l2 b3 t2">
                        <div className="body1 grey1">Sort by </div><div className="l1 body1 black">Date</div>

                </div>
                {props.userPack.logged?
                <>
                                <div className="wide t3" style={{display:"flex",flexWrap:"wrap",justifyContent:"center"}}>
            {props.userPack.user.associatedAddresses.map((add,index)=>(
                <EditAddressCard address={add} key={index} popUpDelete={popUpDelete}/>
            ))}
            
        </div>
                </>:null}

                </div>
        </>
    )
}



