import React from 'react';

export default function(){
    return(
        <>
        <svg width="299" height="31" viewBox="0 0 299 31" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M162.667 15.5C162.667 22.9971 156.731 29 149.5 29C142.269 29 136.333 22.9971 136.333 15.5C136.333 8.00294 142.269 2 149.5 2C156.731 2 162.667 8.00294 162.667 15.5Z" fill="white" stroke="#51A14F" stroke-width="4"/>
<path d="M297 15.5C297 22.9971 291.064 29 283.833 29C276.602 29 270.667 22.9971 270.667 15.5C270.667 8.00294 276.602 2 283.833 2C291.064 2 297 8.00294 297 15.5Z" fill="white" stroke="#C7C7C7" stroke-width="4"/>
<line x1="35.2085" y1="13.5" x2="129.458" y2="13.5" stroke="#51A14F" stroke-width="4"/>
<line x1="169.542" y1="13.5" x2="263.792" y2="13.5" stroke="#C7C7C7" stroke-width="4"/>
<ellipse cx="15.1667" cy="15.5" rx="15.1667" ry="15.5" fill="#51A14F"/>
<g clipPath="url(#clip0)">
<path d="M21.6432 10.1028L20.7463 9.36514C20.3042 9.00202 20.0473 9.00623 19.6688 9.48401L13.1502 17.7054L10.1166 15.1295C9.69809 14.7702 9.43629 14.7893 9.0922 15.2289L8.39991 16.1501C8.04872 16.6019 8.0936 16.8583 8.50875 17.2127L12.8323 20.8675C13.2773 21.2498 13.5279 21.2104 13.872 20.7827L21.7557 11.2037C22.126 10.7488 22.1036 10.477 21.6432 10.1028Z" fill="white"/>
</g>
<defs>
<clipPath id="clip0">
<rect width="15.7083" height="16.0536" fill="white" transform="translate(7.04175 7.19641)"/>
</clipPath>
</defs>
</svg>
        </>
    )
}