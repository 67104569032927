import { Link } from '@reach/router';
import React from 'react';
import Button from '../SvgElements/Button';
import CartIcon from '../SvgElements/CartIcon';
import SmallCartIcon from '../SvgElements/SmallCartIcon';

export default function(props){
    return(
        <>
        
        <div className="bootstrap-wrapper">
        <div className="mobileMainNavBar flexStart col-md-12 hidden-md-up">
            
            <div className="wide logo flexEnd">
                <div className="relative flexStart">
  <div id="menuToggle">

    <input type="checkbox" />
    

    <span></span>
    <span></span>
    <span></span>
    
    
    <ul id="menu" style={{paddingLeft:"70px !important"}}>
      <Link to="/"><li className="l1">Home</li></Link>
      <Link to="/products"><li>Shop</li></Link>
      
      {props.userPack.logged?<Link to="/account"><li>Account</li></Link>:
      <>
      
        <div className="wide alignCenter t2">
            <Button navigate="login" text="Login"/>  <Button navigate="register" text="Register"/>
        </div>
        </>
        }
        <div className="wide alignCenter t4">
             <Button text="Help"/>
        </div>

    </ul>
  </div>
</div>
            <div className="row wide flexEnd">
                <div className="row wide flexEnd">
                    {props.userPack.logged?
                    <div className="col-xs-3 alignCenter navHead1 grey2"><Link to="account">Account</Link></div>
                    :
                    <>
                    <div className="col-xs-3 alignCenter navHead1 grey2 r3"><Link to="register" >Register</Link></div>
                    <div className="col-xs-3 alignCenter navHead1 grey2"><Link to="login">Login</Link></div>
                    </>
                    }
                    
                    <CartIcon/>
                </div>
        </div>
    </div>

    </div>
        <div className="mainNavBar col-xs-12 hidden-sm-down">
            <div className="row alignCenter">
                <div className="title3 button">LEANtech</div>
            </div>
            <div className="container alignCenter">
        <div className="navBarItems">
            <div className="wide alignCenter navHead1 grey2"><Link to="/">HOME</Link></div>
            <div className="wide alignCenter navHead1 grey2"><Link to="products">PRODUCTS</Link></div>
            <div className="wide alignCenter navHead1 grey2"><Link to="account">   ACCOUNT</Link></div>
            <div className="wide alignCenter navHead1 red1"><Link to="cart"><div className="row l2"><SmallCartIcon/><div className="l1"> 0 ITEMS</div></div></Link></div>
        </div>
    </div>

    </div>
        </div>
        </>
    )
}



