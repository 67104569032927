import React, { Component } from 'react';
import BackArrow from '../SvgElements/BackArrow';
import Button from '../SvgElements/Button';
import CheckoutForm from './CheckoutForm';
import {CardElement} from '@stripe/react-stripe-js';

const CardSection=(props)=>{
    return (
      <label>
        Card details
        <CardElement style={{base: {fontSize: '18px'}}} />
      </label>
    );
  }

  function formatNumber(num) {
      if(num.length>6){
          const middle=num.substring(3,6)
          const latter=num.substring(6,10)
          const beg=num.substring(0,3)
          return ("("+beg+") "+middle+"-"+latter)
      }
      return(num)
    }
class NewCard extends Component {
    state = { useAccountName:true,
        billingAddress:{useAccountName:true},fullName:"",cardError:null,
        cardNumber:"",expDate:"",cvv:"",useSameShipping:true }


    handleClickConfirm = async (event) => {
      // We don't want to let default form submission happen here,
      // which would refresh the page.
      event.preventDefault();
      let error=null
      if (!this.props.stripe || !this.props.elements) {
        // Stripe.js has not yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        return;
      }
      const cardElement = this.props.elements.getElement('card');
  
      // From here we can call createPaymentMethod to create a PaymentMethod
      // See our createPaymentMethod documentation for more:
      // https://stripe.com/docs/stripe-js/reference#stripe-create-payment-method
      let paymentIntent
      
      // paymentIntent= (await fetch(
      //   `https://stripe.com/v1/setup_intents`
      // ).then(res => res.json())).client_secret;
      // console.log(paymentIntent);
      let setUpIntent
      await this.props.userPack.axiosPack.axios.post(
        this.props.userPack.axiosPack.baseUrl+'createPaymentIntent/',{customerId:this.props.userPack.stripeCustomer.id}).then(res=>{
          setUpIntent= res.data
          console.log(res,"boboos")})
    const that = this
      await this.props.stripe
        .createPaymentMethod({
          type:'card',
          card: cardElement,
        })
        .then(({paymentMethod}) => {
            if(paymentMethod){
                that.setState({cardError:null})
            }
        }).catch(err=>{
            error=err.message
            console.log(error);})
         this.props.stripe.confirmCardSetup(setUpIntent.client_secret, {
          payment_method: {
            card: cardElement,
          },
        }).then(res=>{
            if(res.error){
                error=res.error
                console.log(res.error,"cool finished")
                that.setState({cardError:res.error.message})
            }
            
        })

      }
    
    handleUpdateLocal=(e)=>{
        let value= e.target.value
        let target = e.target
        let neoBillingAddress = {...this.state.billingAddress}
        if(e.target.name==="phoneNumber"||e.target.name==="zipcode"){
            value=value.replace(/\D/g, '')
            console.log("we in this bitch tho")
            if(neoBillingAddress[e.target.name]&&neoBillingAddress[e.target.name].length>value){
                value=value.substring(0,value.length-1)
            }
            neoBillingAddress[e.target.name]=value
            this.setState({billingAddress:neoBillingAddress})
            return
        }
        
        neoBillingAddress[target.name]=value
        this.setState({billingAddress:neoBillingAddress})
       }
     
    confirmCard=()=>{
        const that=this
        async function test(){ 
        const token = window.localStorage.getItem('token')
        that.props.userPack.methods.axios.defaults.headers.post['Authorization']="JWT "+token

        let newCard = {...that.state}
        console.log("la nueva tarjeta",newCard)
        if(that.state.useSameShipping){
            newCard.billingAddress=that.props.userPack.shippingInfo
        }else{
            newCard.billingAddress=that.state.billingAddress
        }
        newCard.billingAddress.id="6666666"
        delete newCard.billingAddress.useAccountName

        newCard.billingAddress.fullName=newCard.fullName
        newCard.id="6666666"
        
        newCard.expDate=newCard.expMonth+newCard.expYear.substring(0,2)
        delete newCard.expMonth
        delete newCard.expYear
        if(newCard.useAccountName&&that.props.userPack.logged){
            newCard.fullName=that.props.userPack.user.name
            newCard.billingAddress.fullName=that.props.userPack.user.name
        }
        try {
            newCard.customer={id:that.props.userPack.user.id}
            newCard.billingAddress.customer={id:that.props.userPack.user.id}
            await that.props.userPack.methods.axios.post(that.props.userPack.methods.baseUrl+'api/card/',newCard).then(
                res=>{
                    console.log(res,"la maldita respuesta")
                }
            )
        } catch (error) {
            
        }

        let neoOrder = {...that.props.userPack.elements.order}
        neoOrder.card=newCard
        that.props.userPack.methods.stateHandling('order',neoOrder)
        that.props.userPack.refs.routeNewCardRef.current.click()

        that.props.userPack.methods.stateHandling("card",{...that.state})
        that.props.userPack.refs.routeCheckoutRef.current.click()
        that.props.userPack.methods.getUser()
        }
        test()
    }
    handleChange=(e)=>{
        // console.log("actividad",e.target.value,e.target)
        let value= e.target.value
        let target = e.target
        while (true) {
            if(target.getAttribute("name")){
                break
                //
            }else{
                target=target.parentElement
            }
        }

        if(e.target.type==="checkbox"){
            this.setState({[e.target.name]:!this.state[e.target.name]})
        }else{
            if(e.target.name==="fullName"&&this.state.useAccountName&&this.props.userPack.logged){
                return
            }else if(e.target.name==="cardNumber" || e.target.name==="expDate" || e.target.name==="cvv"){
                value=value.replace(/[a-z]/g, '');
            }else if(target.getAttribute("name")==="selected"){
                console.log(target.number,"qloq")
                this.setState({[target.getAttribute("name")]:target.getAttribute("number")})
                return
            }
            // console.log(this.state[e.target.name],e.target.value,"qloqqq")
            this.setState({[e.target.name]:value})
        }
        

    }
    constructor(props){
        super(props)
    }

    render() { 
        const useAccountName=this.state.useAccountName
        return(
            <>
            <div className="navPlain">
                <BackArrow/>
                <div className="title2 alignCenter">Add new payment method</div>
                <div></div>
            </div>
            <div className="alignCenter">
                <div className="mainBody alignCenter" style={{marginBottom:"110px"}}>
                    <div className="wide" style={{marginTop:"20px"}}>
                    <div className="paymentMiniCardBox">
                           <div onClick={this.handleChange} name="selected" number="1" className={this.state.selected==="1"?"paymentMiniCard alignCenter selectedMini":"paymentMiniCard alignCenter"}>
                           <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0)">
    <path d="M21.4843 7.66561C22.0687 8.24999 22.1718 9.10936 21.7593 9.62499L11.5843 21.45C10.9656 22.1719 9.89993 22.1719 9.17806 21.4844L0.515557 12.8219C-0.171943 12.1344 -0.171943 11.0344 0.549932 10.4156L12.3406 0.27499C12.8562 -0.171885 13.7156 -0.0343853 14.2999 0.54999L21.4843 7.66561Z" fill="#AFCDDB"/>
    <path d="M17.4625 3.67814L4.33123 16.6375L1.27185 13.5781L14.9531 1.16876L17.4625 3.67814Z" fill="#333333"/>
    <path d="M16.9812 6.29059L7.76868 15.7437L10.2437 18.2187L19.1468 8.45621L16.9812 6.29059Z" fill="white"/>
    <path d="M11.7219 14.8843C11.2063 14.4031 11.5157 13.5094 11.6875 13.0281C11.9969 12.2719 12.3063 13.7156 12.3063 13.9562C12.375 14.6781 12.2719 15.4343 12.1 16.1562C11.9969 16.5687 11.8594 16.9468 11.6532 17.325C11.55 17.5656 11.3782 17.7718 11.1719 17.9093C10.6563 18.2187 10.3469 17.9093 10.2438 17.05C10.3125 16.775 10.4157 16.5343 10.6219 16.3281C11.275 15.5031 12.3063 15.125 13.0625 14.4718C13.1313 14.4031 13.0282 14.3 12.9594 14.3687C12.0657 15.125 10.2094 15.7437 10.1063 17.2218C10.0375 18.1843 11.0344 18.425 11.55 17.875C12.2375 17.1531 12.4094 15.8812 12.4782 14.9187C12.5125 14.3687 12.65 12.7875 11.825 12.6156C11.7907 12.6156 11.7563 12.6156 11.7563 12.65C11.4125 13.2343 10.9657 14.4375 11.6532 15.0562C11.6875 15.0906 11.7907 14.9531 11.7219 14.8843" fill="#4F5B60"/>
    <path d="M12.8563 14.3344C13.5782 14.7469 13.7844 13.1313 13.6813 12.6844C13.6813 12.6156 13.5782 12.6156 13.5438 12.65C13.1657 12.9594 13.0969 13.7844 13.4063 14.2656C13.4407 14.3344 13.5094 14.3344 13.5438 14.2656C13.7844 13.8188 13.9219 13.3719 14.0938 12.8906C14.1626 12.6844 14.3344 12.7531 14.4719 12.6156L15.2969 11.7906C15.6407 11.4469 15.7438 10.9656 16.0532 10.6219C16.4657 10.175 16.8438 9.96875 17.0501 9.35C17.0844 9.2125 17.0844 8.86875 16.8438 8.86875C16.3626 8.83438 15.9501 9.83125 16.6032 9.72813C17.3594 9.625 18.0469 8.73125 18.5282 8.25C18.5969 8.18125 18.4938 8.07813 18.4251 8.14688C18.1844 8.3875 17.9094 8.62813 17.6688 8.83438C17.4626 9.00625 17.2563 9.17813 17.0157 9.35C16.9126 9.41875 16.0188 9.83125 16.5688 9.17813C16.6719 9.24688 16.7751 9.31563 16.8782 9.41875C16.8438 9.4875 16.8094 9.59063 16.7751 9.65938C16.7063 9.79688 16.6032 9.93438 16.5001 10.0719C16.3282 10.3125 16.0188 10.45 15.8126 10.6906C15.5376 11 15.4344 11.4125 15.1594 11.7219C15.0219 11.8594 14.3688 12.7188 14.1282 12.6156C14.0938 12.6156 14.0594 12.6156 14.0594 12.65C13.9907 12.8906 13.9219 13.0969 13.8188 13.3375C13.7844 13.4063 13.4751 14.0594 13.4751 14.0938C13.3376 13.75 13.3719 13.3375 13.5094 13.0281C13.6126 13.2 13.6126 13.4063 13.5438 13.5781C13.4751 13.9906 13.2688 14.2313 12.9251 14.3C12.8219 14.1281 12.7532 14.2656 12.8563 14.3344" fill="#4F5B60"/>
    </g>
    <defs>
    <clipPath id="clip0">
    <rect width="22" height="22" fill="white"/>
    </clipPath>
    </defs>
    </svg>
    
                           </div>
                           <div onClick={this.handleChange} name="selected" number="2" className={this.state.selected==="2"?"paymentMiniCard alignCenter selectedMini":"paymentMiniCard alignCenter"}>
                           <svg width="16" height="19" viewBox="0 0 16 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.573 1.47891C12.7021 0.479711 11.1279 0.0513306 9.11395 0.0513306H3.26895C3.06969 0.051338 2.87697 0.122898 2.72543 0.253144C2.5739 0.38339 2.47349 0.56378 2.44226 0.76188L0.00851289 16.2995C-0.0398621 16.6059 0.195825 16.8834 0.504325 16.8834H4.11283L5.01908 11.0971L4.99095 11.2783C5.05551 10.8693 5.40301 10.5676 5.81451 10.5676H7.52926C10.8979 10.5676 13.5356 9.19033 14.3061 5.20606C14.329 5.08822 14.3488 4.97353 14.3659 4.86148C14.2686 4.80964 14.2686 4.80964 14.3659 4.86148C14.5953 3.38885 14.3643 2.38644 13.573 1.47891" fill="#27346A"/>
    <path d="M6.39983 4.33099C6.49848 4.2837 6.60637 4.2592 6.71564 4.25926H11.298C11.8406 4.25926 12.3468 4.29481 12.8093 4.36974C12.9387 4.39051 13.0674 4.41531 13.1953 4.44411C13.3766 4.48437 13.5558 4.53365 13.7322 4.59176C13.9596 4.6682 14.1713 4.75723 14.366 4.86148C14.5953 3.38828 14.3643 2.38644 13.573 1.47891C12.7016 0.479711 11.1279 0.0513306 9.11395 0.0513306H3.26845C2.85689 0.0513306 2.50676 0.35294 2.44226 0.76188L0.00851323 16.299C-0.0398618 16.6059 0.195826 16.8829 0.503826 16.8829H4.11283L5.99301 4.88085C6.0115 4.76288 6.05818 4.6512 6.12905 4.55542C6.19992 4.45963 6.29284 4.38261 6.39983 4.33099V4.33099Z" fill="#27346A"/>
    <path d="M14.3061 5.20603C13.5356 9.18974 10.898 10.5676 7.52932 10.5676H5.81407C5.40257 10.5676 5.05501 10.8693 4.99107 11.2783L3.86364 18.4728C3.82151 18.741 4.02751 18.9839 4.29701 18.9839H7.33889C7.51313 18.9839 7.68165 18.9213 7.81412 18.8073C7.9466 18.6934 8.03434 18.5356 8.06157 18.3623L8.0912 18.2063L8.66451 14.5487L8.70145 14.3466C8.72868 14.1734 8.81641 14.0156 8.94887 13.9017C9.08134 13.7877 9.24984 13.7251 9.42407 13.725H9.87932C12.826 13.725 15.1333 12.52 15.8076 9.03478C16.089 7.57832 15.9434 6.36232 15.1989 5.50821C14.9731 5.2495 14.6926 5.03585 14.3659 4.86145C14.3483 4.97407 14.3291 5.08819 14.3061 5.20603V5.20603Z" fill="#2790C3"/>
    <path d="M13.5595 4.53786C13.4394 4.50255 13.3181 4.47129 13.1959 4.44412C13.0679 4.4158 12.9392 4.39117 12.8098 4.37026C12.3468 4.29483 11.8411 4.25922 11.298 4.25922H6.71614C6.6068 4.25897 6.49882 4.28368 6.40033 4.33151C6.29322 4.38297 6.20019 4.45995 6.1293 4.55576C6.0584 4.65157 6.01177 4.76334 5.99346 4.88137L5.01958 11.0971L4.99146 11.2783C5.05552 10.8693 5.40302 10.5677 5.81458 10.5677H7.52983C10.8985 10.5677 13.5361 9.19035 14.3066 5.20607C14.3295 5.08823 14.3488 4.97405 14.3665 4.86149C14.1713 4.75781 13.9601 4.66822 13.7327 4.59228C13.6753 4.57313 13.6176 4.55499 13.5596 4.53786" fill="#1F264F"/>
    </svg>
    
                               </div>
                               <div onClick={this.handleChange} name="selected" number="3" className={this.state.selected==="3"?"paymentMiniCard alignCenter selectedMini":"paymentMiniCard alignCenter"}>
                               <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M23.636 14.9015C22.034 21.3357 15.5217 25.2417 9.09722 23.6405C2.67122 22.0505 -1.24303 15.5225 0.361968 9.10471C1.96022 2.67196 8.47622 -1.24229 14.897 0.35896C21.3267 1.96471 25.241 8.47321 23.636 14.906V14.9015ZM17.288 10.289C17.5257 8.69896 16.3107 7.84021 14.6472 7.26196L15.1872 5.10871L13.8702 4.77646L13.3475 6.88621C13.0032 6.79621 12.6447 6.71821 12.2847 6.63646L12.809 4.50721L11.4882 4.17946L10.949 6.34321L10.109 6.14446L8.29697 5.69071L7.94522 7.10146C7.94522 7.10146 8.92247 7.32346 8.89847 7.33546C9.43472 7.47271 9.53147 7.81921 9.51647 8.10121L8.03972 14.0225C7.96547 14.1875 7.79747 14.4297 7.42697 14.3352C7.43897 14.354 6.46622 14.0975 6.46622 14.0975L5.80622 15.6042L7.51697 16.0302L8.44622 16.2725L7.90322 18.4647L9.22397 18.7887L9.76697 16.6205C10.1262 16.7225 10.4697 16.8117 10.8132 16.8942L10.3062 19.046L11.6262 19.3782L12.1692 17.1875C14.411 17.6127 16.0992 17.4447 16.8102 15.4137C17.3802 13.7765 16.778 12.836 15.5952 12.2187C16.4465 12.023 17.0952 11.4567 17.2745 10.289H17.288ZM14.276 14.5115C13.874 16.1517 11.12 15.2615 10.226 15.0387L10.9482 12.1407C11.843 12.371 14.702 12.812 14.276 14.5115ZM14.687 10.2695C14.315 11.7612 12.026 11.0075 11.2797 10.8192L11.9367 8.17921C12.6785 8.35846 15.0725 8.70196 14.687 10.265V10.2695Z" fill="black"/>
    </svg>
    
                               </div>

                        </div>
                        <div className="wide t3">
                        <div className="title3">Enter your credit card information</div>
                        </div>
                    <div className="wide t3"></div>
                    {this.state.cardError?
                    <div className="wide alignCenter b3">
                        <div className="title3 red1">{this.state.cardError}</div>
                        </div>
                    :null}

      <CheckoutForm stripe={this.props.stripe} elements={this.props.elements} userPack={this.props.userPack}/>
    <div className="wide t3">
    
                            <label className="pure-material-checkbox">
                            <input type="checkbox" name="useSameShipping" defaultChecked={this.state.useSameShipping} onChange={this.handleChange}/>
                                <span style={{zIndex:"-1"}}></span>
                                Use same shipping address as billing address.
                                </label>
                                </div>
                            {this.state.useSameShipping?
                            null
                            :
                            <>
                                        <div className="wide flexStart">
                {this.props.userPack.logged?
                <>
                                        <div className="flexStart">
                        <label className="pure-material-checkbox">
                            <input name="useAccountName" onChange={this.handleUpdateLocal} type="checkbox" style={{zIndex:"90"}} defaultChecked={this.state.billingAddress.useAccountName}/>
                            <span style={{zIndex:"-1"}}></span>
                            Use account name
                            </label>
                            </div>
                </>
                :null}

{/* <div className="material-textfield">
      <input onChange={handleUpdate} placeholder=" " type="text"/>
      <label>Label</label>
  </div> */}
                            </div>
                <div className="wide" style={{marginTop:"0px"}}>
                <form>
                <fieldset className="material">
		<input name="fullName" value={this.state.billingAddress.fullName} onChange={this.handleUpdateLocal} type="text"  autoComplete="off" required />
		<hr/>
		<label>Full name</label>
	</fieldset>
	<fieldset className="material">
		<input name="address1" value={this.state.billingAddress.address1} onChange={this.handleUpdateLocal} type="text"  autoComplete="off" required />
		<hr/>
		<label>Address 1</label>
	</fieldset>


	<fieldset className="material">
		<input name="address2" value={this.state.billingAddress.address2} onChange={this.handleUpdateLocal} type="text"  autoComplete="off" required />
		<hr/>
		<label>Address 2</label>
	</fieldset>
        <div className="half">
        <fieldset className="material">
		<input name="city" value={this.state.billingAddress.city} onChange={this.handleUpdateLocal} type="text"  autoComplete="off" required />
		<hr/>
		<label>City</label>
	</fieldset>
    <fieldset className="material">
		<input name="state" value={this.state.billingAddress.state} onChange={this.handleUpdateLocal} type="text"  autoComplete="off" required />
		<hr/>
		<label>State</label>
	</fieldset>
        </div>
        <div className="half">
        <fieldset className="material">
		<input name="zipcode" value={this.state.billingAddress.zipcode} onChange={this.handleUpdateLocal} type="number"  autoComplete="off" required />
		<hr/>
		<label>Zipcode</label>
	</fieldset>
    <fieldset className="material">
		<input name="phoneNumber" value={this.state.billingAddress.phoneNumber?formatNumber(this.state.billingAddress.phoneNumber):formatNumber("")} onChange={this.handleUpdateLocal} type="tel"  autoComplete="off" required />
		<hr/>
		<label>Phone number</label>
	</fieldset>
        </div>
</form>
        </div>
                            </>
                            }
    
    
            </div>
            </div>
    
            {/* // */}
    
    
            </div>
            {this.props.userPack.dimensions.width<769?<>
                <div className="wide t4" style={{position:"fixed",bottom:"0px",width:"100vw" }}>
                           <div className="wide alignCenter" style={{height:"70px",width:"100%", backgroundColor:"white"}}>
                            <Button buttonId="newCard" text="Confirm" action={this.handleClickConfirm}/>
                            </div>
                        </div>
            </>:null}
    
            {this.props.userPack.dimensions.width>769?<>
                <div className="wide alignCenter t4" style={{position:"fixed",bottom:"0px",width:"100vw"}}>
                           <div className="wide alignCenter" style={{height:"70px",width:"100%", backgroundColor:"white"}}>
                            <Button text="Confirm" buttonId="newCard" action={this.handleClickConfirm} style="4"/>
                            </div>
                        </div>
            </>:null}
            </>
        )
    }
}
 
export default NewCard;
    



