import { Link } from '@reach/router';
import React from 'react';
import BackArrow from '../SvgElements/BackArrow';
import Button from '../SvgElements/Button';
import RightArrow from '../SvgElements/RightArrow';
import Loader from './Loader';
import GoogleLogin from 'react-google-login';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import Account from './Account';

export default function(props){
    const [register,setRegister]=React.useState(window.location.pathname==="/register")
    const [step,stepper] = React.useState(0)
    const [loading,setLoading] = React.useState(false)
    const logged = props.userPack.logged
    
    const nextStep=(e)=>{
      e.preventDefault()
      stepper(prev=>prev+1);
    }
    const updatePhone=(e)=>{
      let newVal = {...props.userPack.loginModal}
      newVal.phoneNumber=e.target.value
      props.userPack.switchLoginModal(newVal)
    }
    const updateFirstName=(e)=>{
      let newVal = {...props.userPack.loginModal}
      newVal.firstName=e.target.value
      props.userPack.switchLoginModal(newVal)
    }
    const updateLastName=(e)=>{
      let newVal = {...props.userPack.loginModal}
      newVal.lastName=e.target.value
      props.userPack.switchLoginModal(newVal)
    }
    const registerFunction=(e)=>{
      console.log("y qs lok")
      e.preventDefault()
      props.userPack.methods.handleRegister()
    }
    const prevStep=(e)=>{
      e.preventDefault()
      stepper(prev=>prev-1);
    }
    const updateUsername=(e)=>{
      let newVal = {...props.userPack.loginModal}
      newVal.username=e.target.value
      props.userPack.switchLoginModal(newVal)
    }
    const updatePassword=(e)=>{
      let newVal = {...props.userPack.loginModal}
      newVal.password=e.target.value
      props.userPack.switchLoginModal(newVal)
    }
    const login=(e)=>{
      props.userPack.methods.login(props.userPack.loginModal.username,props.userPack.loginModal.password)
    }
    const close=()=>{
      let newVal = {...props.userPack.loginModal}
      newVal.active=false
      props.userPack.switchLoginModal(newVal)
    }
    const openRegister=()=>{
      let newVal = {...props.userPack.loginModal}
      if(window.location.href.includes("loginMobile")){
        newVal.login=true
        props.userPack.switchLoginModal(newVal)
      }else{
        newVal.active=true
        newVal.login=false
        props.userPack.switchLoginModal(newVal)
      }
      

    }
    const responseGoogle = (e) => {
      console.log("qloq",e.profileObj)
      const data = e.profileObj
      let newVal = {...props.userPack.loginModal}
      newVal.username=data.email
      newVal.password="Default3xsd1Pass"
      newVal.social=true
      props.userPack.switchLoginModal(newVal)
      props.userPack.methods.handleRegister(data)
    }
    const responseFacebook = (e) => {
      //e.preventDefault()

      console.log("still here",e)
      if(e.status!=="unknown"){
        let newVal = {...props.userPack.loginModal}
        newVal.username=e.email
        newVal.password="Default3xsd1Pass"
        newVal.social=true
        props.userPack.switchLoginModal(newVal)
        props.userPack.methods.handleRegister({...e})
      }
    }
    return(
        <>
        {logged?
        <>
        <Account userPack={props.userPack}/>
        </>:
        <>
        <div className="navPlain">
            <BackArrow/>
            <div className="title2 alignCenter">{register?"Register":"Login"}</div>
            <div></div>
        </div>
        <div className="alignCenter">
            <div className="mainBody alignCenter" style={{minHeight:"75vh"}}>
{props.userPack.loginModal.innerLoading?
<Loader/>
:
<>

                <div className="t5" style={{minWidth:"360px"}}>
                {props.userPack.loginModal.innerError?<div className="alignCenter justify" style={{top:"-12px",position:"relative",zIndex:"2"}}>
<p className="normalText"><span style={{color:"red"}}>{props.userPack.loginModal.innerError}</span></p>
                </div>:null}
{register?
<>
{step===0?
            <>
             <div className="fieldBox upperFieldBox relative">
                <div className="caption3" style={{position:"absolute",top:"6px"}}>Country/Region</div>
                <div className="body1" style={{position:"absolute",bottom:"10px"}} >United States (+1)</div>
                <a href="#">
                <svg style={{position:"Absolute",right:"15px"}} width="22" height="13" viewBox="0 0 22 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M21.0989 1.37235L20.0087 0.331722C19.5422 -0.118278 18.781 -0.108903 18.3243 0.350472L10.7227 6.90204L3.12076 0.350472C2.66406 -0.104215 1.9029 -0.11359 1.43638 0.331722L0.346205 1.37235C-0.115402 1.80828 -0.115402 2.52079 0.346205 2.96141L9.89263 12.0739C10.3493 12.5145 11.0958 12.5145 11.5574 12.0739L16.3281 7.51766L21.0989 2.96141C21.5605 2.52547 21.5605 1.81297 21.0989 1.37235Z" fill="#2D2D2D"/>
                  </svg>
                  </a>
              </div>
              <div className="fieldBox bottomFieldBox">
                <input  onChange={updatePhone} value={props.userPack.loginModal.phoneNumber}  type="tel" pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" required style={{width:"95%"}} className="body1 clearThrough" placeholder="Phone number"></input>
              </div>
  
              {/* <div className="fieldBox"  style={{marginTop:"15px"}}>
                <input type="password" style={{width:"95%"}} className="clearThrough body1" placeholder="Contraseña"></input>
              </div> */}
              <a href="#" onClick={nextStep} className="wideButton alignCenter" style={{marginTop:"15px"}}>
                    <div className="title2 white textAlign">Continuar</div>
                </a>
                <div className="link" onClick={nextStep}>
                      <div className="optionBox alignCenter justify" style={{marginTop:"15px"}}>
                        <div style={{position:"absolute",left:"20px"}}>
                        <svg width="23" height="19" viewBox="0 0 23 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M11.5 14.25C9.66 14.25 7.82 12.5875 6.9 11.875C2.3 8.55 0.92 7.3625 0 6.65V16.625C0 17.9364 1.02997 19 2.3 19H20.7C21.97 19 23 17.9364 23 16.625V6.65C22.08 7.3625 20.7 8.55 16.1 11.875C15.18 12.5875 13.34 14.25 11.5 14.25ZM20.7 0H2.3C1.02997 0 0 1.06355 0 2.375V3.5625C1.84 4.9875 1.61 4.9875 8.28 9.975C8.97 10.45 10.35 11.875 11.5 11.875C12.65 11.875 14.03 10.45 14.72 10.2125C21.39 5.225 21.16 5.225 23 3.8V2.375C23 1.06355 21.97 0 20.7 0Z" fill="black"/>
                  </svg>
          
                        </div>
                        <div className="normalText">Continue with Email</div>
                      </div>
                      </div>

                <FacebookLogin
                    appId="327442284949288"
                    disableMobileRedirect={true}
                    callback={responseFacebook}
                    fields="name,first_name,last_name,email,picture"
                    render={renderProps => (
                      <div className="link" onClick={renderProps.onClick}>
                      <div className="optionBox alignCenter justify" style={{marginTop:"15px"}}>
                        <div style={{position:"absolute",left:"20px"}}>
          <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M21.7306 23C22.4316 23 23 22.4317 23 21.7306V1.2694C23 0.568259 22.4316 0 21.7306 0H1.2694C0.568172 0 0 0.568259 0 1.2694V21.7306C0 22.4317 0.568172 23 1.2694 23H21.7306Z" fill="#395185"/>
          <path d="M17.789 23V13.8771H21.327L21.8567 10.3218H17.789V8.05172C17.789 7.02234 18.1193 6.32085 19.8248 6.32085L22 6.32002V3.14015C21.6236 3.09681 20.3325 3 18.8304 3C15.6941 3 13.5471 4.65688 13.5471 7.69973V10.3218H10V13.8771H13.5471V23H17.789Z" fill="white"/>
          </svg>
          
                        </div>
                        <div className="normalText">Continue with Facebook</div>
                      </div>
                      </div>
                    )}
                  />

              <GoogleLogin
                    scope="email https://www.googleapis.com/auth/analytics https://www.googleapis.com/auth/analytics.edit https://www.googleapis.com/auth/analytics.readonly"
                      clientId="483932223498-7aopai2dl8boii87lrjf6nto5ob0opnt.apps.googleusercontent.com"
                      render={renderProps => (
                        <div className="link" onClick={renderProps.onClick}>
                        <div className="optionBox alignCenter justify" style={{marginTop:"15px"}}>
                          <div style={{position:"absolute",left:"20px"}}>
                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M23.4398 9.71506H22.5V9.66665H12V14.3333H18.5935C17.6315 17.0499 15.0468 19 12 19C8.13429 19 5.00004 15.8657 5.00004 12C5.00004 8.13423 8.13429 4.99998 12 4.99998C13.7845 4.99998 15.4079 5.67315 16.644 6.77273L19.9439 3.47281C17.8602 1.5309 15.073 0.333313 12 0.333313C5.55712 0.333313 0.333374 5.55706 0.333374 12C0.333374 18.4429 5.55712 23.6666 12 23.6666C18.443 23.6666 23.6667 18.4429 23.6667 12C23.6667 11.2177 23.5862 10.4541 23.4398 9.71506Z" fill="#FFC107"/>
<path d="M1.67847 6.56973L5.51155 9.38081C6.54872 6.81298 9.06055 4.99998 12 4.99998C13.7844 4.99998 15.4078 5.67315 16.6439 6.77273L19.9438 3.47281C17.8601 1.5309 15.073 0.333313 12 0.333313C7.5188 0.333313 3.63263 2.86323 1.67847 6.56973Z" fill="#FF3D00"/>
<path d="M12 23.6666C15.0135 23.6666 17.7517 22.5134 19.8219 20.638L16.2111 17.5825C15.0004 18.5032 13.521 19.0012 12 19C8.96552 19 6.38894 17.0651 5.41827 14.3648L1.61377 17.2961C3.5446 21.0743 7.46577 23.6666 12 23.6666Z" fill="#4CAF50"/>
<path d="M23.4398 9.7151H22.5V9.66669H12V14.3334H18.5934C18.1333 15.6263 17.3045 16.756 16.2093 17.5831L16.2111 17.5819L19.8219 20.6374C19.5664 20.8696 23.6667 17.8334 23.6667 12C23.6667 11.2178 23.5862 10.4542 23.4398 9.7151Z" fill="#1976D2"/>
</svg>

                          </div>
                          <div className="normalText">Continue with Google</div>
                        </div>
                        </div>
                      )}
                      buttonText="Login"
                      onSuccess={responseGoogle}
                      cookiePolicy={'single_host_origin'}/>

            </>
            :null}
                {step===1?
            <>
            <a href="#">
            <svg onClick={prevStep} style={{position:"relative",bottom:"20px"}} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M11.4965 18.6605L10.5055 19.6772C10.0859 20.1077 9.40743 20.1077 8.9923 19.6772L0.314697 10.7786C-0.104899 10.3481 -0.104899 9.65199 0.314697 9.22607L8.9923 0.322929C9.4119 -0.107572 10.0849 -0.107675 10.5 0.322827L11.4786 1.5001C10.9832 0.991744 9.93299 2.07409 9.5 2.50001L2.5 9.26728L18.5 9C19.0937 9 20 8.65817 20 9.26728V10.7328C20 11.3419 19.5937 11 19 11L2.5 10.7786L11 18.5C11.4375 18.9259 11.925 18.2254 11.4965 18.6605Z" fill="#616161"/>
  </svg></a>
  
          <div className="fieldBox upperFieldBox">
          <input onChange={updateFirstName} value={props.userPack.loginModal.firstName}  type="text" style={{width:"95%"}} className="clearThrough body1" placeholder="First name"/>
              </div>
              <div className="fieldBox bottomFieldBox">
              <input onChange={updateLastName} value={props.userPack.loginModal.lastName}  type="text" style={{width:"95%"}} className="clearThrough body1" placeholder="Last name"/>
              </div>
              <div className="fieldBox"  style={{marginTop:"15px"}}>
              <input onChange={updateUsername} value={props.userPack.loginModal.username} type="email" style={{width:"95%"}} className="clearThrough body1" placeholder="Email"/>
              </div>
              <div className="fieldBox"  style={{marginTop:"15px"}}>
              <input onChange={updatePassword} value={props.userPack.loginModal.password} type="password" style={{width:"95%"}} className="clearThrough body1" placeholder="Password"/>
              </div>
              
              <a href="#" onClick={registerFunction} className="wideButton alignCenter" style={{marginTop:"15px"}}>
                    <div className="title2 white textAlign">Sign up</div>
                </a>
                </> :null}
                <Link to={"../login"} className="flexStart t4">
                <p className="normalText">Do you already have an account? <span style={{color:"#37A000",fontSize:"26px !important"}}>Login now.</span></p>
                </Link>
  
           
</>
:
<>
<div className="fieldBox upperFieldBox">
            <input onChange={updateUsername} value={props.userPack.loginModal.username} type="text" style={{width:"100%"}} className="clearThrough body1" placeholder="Email or phone number"/>
                </div>
                <div className="fieldBox bottomFieldBox">
                <input onChange={updatePassword} value={props.userPack.loginModal.password} type="password" style={{width:"100%"}} className="clearThrough body1" placeholder="Password"/>

                </div>
                </>
}

                
                

                    {register?null
                    :
                    <>
                    <a href="#" onClick={register?registerFunction:login} className="wideButton alignCenter" style={{marginTop:"15px"}}>
                    <div className="title2 white textAlign">{register?"Sign up":"Log in"}</div>
                </a>
            
                <FacebookLogin
                    appId="327442284949288"
                    disableMobileRedirect={true}
                    callback={responseFacebook}
                    fields="name,first_name,last_name,email,picture"
                    render={renderProps => (
                      <div className="link" onClick={renderProps.onClick}>
                      <div className="optionBox alignCenter justify" style={{marginTop:"15px"}}>
                        <div style={{position:"absolute",left:"20px"}}>
          <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M21.7306 23C22.4316 23 23 22.4317 23 21.7306V1.2694C23 0.568259 22.4316 0 21.7306 0H1.2694C0.568172 0 0 0.568259 0 1.2694V21.7306C0 22.4317 0.568172 23 1.2694 23H21.7306Z" fill="#395185"/>
          <path d="M17.789 23V13.8771H21.327L21.8567 10.3218H17.789V8.05172C17.789 7.02234 18.1193 6.32085 19.8248 6.32085L22 6.32002V3.14015C21.6236 3.09681 20.3325 3 18.8304 3C15.6941 3 13.5471 4.65688 13.5471 7.69973V10.3218H10V13.8771H13.5471V23H17.789Z" fill="white"/>
          </svg>
          
                        </div>
                        <div className="normalText">Continue with Facebook</div>
                      </div>
                      </div>
                    )}
                  />

              <GoogleLogin
                    scope="email https://www.googleapis.com/auth/analytics https://www.googleapis.com/auth/analytics.edit https://www.googleapis.com/auth/analytics.readonly"
                      clientId="483932223498-7aopai2dl8boii87lrjf6nto5ob0opnt.apps.googleusercontent.com"
                      render={renderProps => (
                        <div className="link" onClick={renderProps.onClick}>
                        <div className="optionBox alignCenter justify" style={{marginTop:"15px"}}>
                          <div style={{position:"absolute",left:"20px"}}>
                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M23.4398 9.71506H22.5V9.66665H12V14.3333H18.5935C17.6315 17.0499 15.0468 19 12 19C8.13429 19 5.00004 15.8657 5.00004 12C5.00004 8.13423 8.13429 4.99998 12 4.99998C13.7845 4.99998 15.4079 5.67315 16.644 6.77273L19.9439 3.47281C17.8602 1.5309 15.073 0.333313 12 0.333313C5.55712 0.333313 0.333374 5.55706 0.333374 12C0.333374 18.4429 5.55712 23.6666 12 23.6666C18.443 23.6666 23.6667 18.4429 23.6667 12C23.6667 11.2177 23.5862 10.4541 23.4398 9.71506Z" fill="#FFC107"/>
<path d="M1.67847 6.56973L5.51155 9.38081C6.54872 6.81298 9.06055 4.99998 12 4.99998C13.7844 4.99998 15.4078 5.67315 16.6439 6.77273L19.9438 3.47281C17.8601 1.5309 15.073 0.333313 12 0.333313C7.5188 0.333313 3.63263 2.86323 1.67847 6.56973Z" fill="#FF3D00"/>
<path d="M12 23.6666C15.0135 23.6666 17.7517 22.5134 19.8219 20.638L16.2111 17.5825C15.0004 18.5032 13.521 19.0012 12 19C8.96552 19 6.38894 17.0651 5.41827 14.3648L1.61377 17.2961C3.5446 21.0743 7.46577 23.6666 12 23.6666Z" fill="#4CAF50"/>
<path d="M23.4398 9.7151H22.5V9.66669H12V14.3334H18.5934C18.1333 15.6263 17.3045 16.756 16.2093 17.5831L16.2111 17.5819L19.8219 20.6374C19.5664 20.8696 23.6667 17.8334 23.6667 12C23.6667 11.2178 23.5862 10.4542 23.4398 9.7151Z" fill="#1976D2"/>
</svg>

                          </div>
                          <div className="normalText">Continue with Google</div>
                        </div>
                        </div>
                      )}
                      buttonText="Login"
                      onSuccess={responseGoogle}
                      cookiePolicy={'single_host_origin'}/>
                                    <Link to="../register" className="flexStart t4">
                <p className="normalText">Not yet registered? <span style={{color:"#37A000",fontSize:"26px !important"}}>Register.</span></p>
                </Link>
                <a href="#" onClick={openRegister} className="flexStart">
                <p className="normalText">Trouble signing in? <span style={{color:"#37A000"}}>Forgot my password.</span></p>
                </a>
                    </>
                    }

        
                    </div>
              
        </>}
        </div>
        </div>
        </>}
        </>
    )}
//     return(
//         <>
//         <div className="navPlain">
//             <BackArrow/>
//             <div className="title2 alignCenter">Login</div>
//             <div></div>
//         </div>
//         <div className="alignCenter">
//             <div className="mainBody" style={{marginBottom:"110px"}}>
//                 <div className="wide alignCenter">

//                     </div>
//             </div>
//         </div>

//         {/* <section>
//   <h1>Simple material design CSS only checkbox example</h1>
//   <div className="md-checkbox">
//     <input id="i2" type="checkbox" defaultChecked={true}/>
//     <label for="i2">Item 1</label>
//   </div>
//   <div className="md-checkbox">
//     <input id="i1" type="checkbox"/>
//     <label for="i1">Item 2</label>
//   </div>
//   <div className="md-checkbox">
//     <input id="i3" type="checkbox"/>
//     <label for="i3"></label>
//   </div>
  
//   <div className="md-checkbox">
//     <input id="i4" type="checkbox" disabled="disabled" defaultChecked={true}/>
//     <label for="i4">Disabled</label>
//   </div>
//   <div className="md-checkbox">
//     <input id="i5" type="checkbox" disabled="disabled"/>
//     <label for="i5">Disabled</label>
//   </div>
  
//   <div style={{textAlign:"center"}}>
//     <div className="md-checkbox md-checkbox-inline">
//       <input id="i10" type="checkbox"/>
//       <label for="i10">Inline & Centered</label>
//     </div>
//   </div>  
  
//   <div>
//     <p>
//       Checkboxes use relative units and will scale to 
//       your font size. By default the checkbox size is 
//       set to 1.25em ($md-checkbox-size).
//     </p>
//     <div className="md-checkbox" style={{fontSize:"20px"}}>
//       <input id="i12" type="checkbox" defaultChecked={true}/>
//       <label for="i12">Scales with font size</label>
//     </div>
//   </div>
// </section> */}

//         </>




