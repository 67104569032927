import React from 'react';
import NavBar from '../generalComponents/NavBar';
import LeanTech from './LeanTech';

export default function(props){
    return(
        <>
        <div className="wide alignCenter red2" style={{zIndex:"9999",padding:"5px",position:"fixed",top:"0px"}}>
            <div className="title3 white">FREE SHIPPING ON ORDERS $75+</div>
        </div>
        <NavBar userPack={props.userPack}/>
        <div className="landingBackground">
            <div className="bootstrap-wrapper">
                <div className="wide alignCenter absolute" style={{bottom:"20%"}}>
                <div className="col-xs-12 hidden-md-up">
                <LeanTech/>
                </div>
                
                </div>

           
            </div>
            <div className="bigShade flexEnd">
   
                <div className="wide alignCenter">
                <LeanTech/>
                </div>
            </div>
        </div>
        </>
    )
}



