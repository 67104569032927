import { Link } from '@reach/router';
import React from 'react';
import BackArrow from '../SvgElements/BackArrow';
import Button from '../SvgElements/Button';
import RightArrow from '../SvgElements/RightArrow';

export default function(props){
    return(
        <>
        <div className="navPlain">
            <BackArrow/>
            <div className="title2 alignCenter">Cart</div>
            <div></div>
        </div>
        <div className="alignCenter">
            <div className="mainBody" style={{marginBottom:"110px"}}>
                <div className="wide" style={{marginTop:"100px"}}>
                    <div className="title1">Your cart is currently empty.</div>
                </div>
                <div className="wide t5">
                    <Button text="Go shopping!"/>
                </div>
      </div>
      </div>
        </>
    )
}



