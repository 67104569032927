

import React from 'react';
import Button from '../SvgElements/Button';

export default function(){
    return(
        <>
        <div className="wide alignCenter">
        <div className="col-xs-12">
<div className="row alignCenter">
    <div className="mainTitle1">LEAN</div>
</div>
<div className="row alignCenter">
    <div className="mainTitle2">TECH</div>
</div>
<div className="row alignCenter t4">
    <Button text="SHOP" style="2"/>
</div>
</div>
</div>
        </>
    )
}



