import React from 'react';
import Button from '../SvgElements/Button';
import CheckoutForm from '../Components/CheckoutForm';
import BankPayment from '../Components/BankPayment';

export default function(props){
    const [applyLoading,setApplyLoading]=React.useState(false)
    const handleApply = (e)=>{
        e.preventDefault()
        e.stopPropagation()
        setApplyLoading(prev=>!prev)
    }
    const [payNowLoading,setPayNowLoading]=React.useState(false)
    const [paymentType,setPaymentType]=React.useState('card')
    const handlePaymentType=(e)=>{
        setPaymentType(e.target.name)
    }
    const handlePayNow = (e)=>{
        e.preventDefault()
        e.stopPropagation()
        setPayNowLoading(prev=>!prev)
    }
    return(
        <>
 <div className="wide roundedBorder" style={{padding:"16px"}}>
     <div className="half">
         <div className="flexStart">
         <div className="body2 grey2"> Contact</div>
         </div>
         <div className="flexEnd">
         <div className="body3 button" onClick={props.goInfo}> Change</div>
         </div>
         
     </div>
    <div className="wide t1">
        <div className="body2">{props.userPack.logged?props.userPack.user.username:props.userPack.order?props.userPack.order.userName===""?"Please provide a valid email address":props.userPack.order.userName:"wait"}</div>
    </div>
    <div className="wide alignCenter">
        <div className="hrElement t3"></div>
    </div>
    <div className="half t3">
         <div className="flexStart">
         <div className="body2 grey2"> Shipping address</div>
         </div>
         <div className="flexEnd">
         <div className="body3 button" onClick={props.goInfo}> Change</div>
         </div>
         
     </div>
     
    <div className="wide t1">
        <div className="body2">{props.userPack.shippingInfo.address1&&props.userPack.shippingInfo.zipcode?props.userPack.shippingInfo.address1+", "+props.userPack.shippingInfo.city+", "+props.userPack.shippingInfo.state+" "+props.userPack.shippingInfo.zipcode+", United States":"Please instert a valid address"}</div>
    </div>
    <div className="wide alignCenter">
        <div className="hrElement t3"></div>
    </div>
    <div className="wide t3">
         <div className="flexStart">
         <div className="body2 grey2">Shipping method</div>
         </div>
         
     </div>
     <div className="wide">
     <div className="body2 t1">USPS First Class Package · $5.00</div>
 </div>
 </div>
 {props.userPack.dimensions.width<769?
 <>
  <div className="wide t4">
     <div className="title3">Gift card or discount code</div>
 </div>
 <div className="wide t2">
     <div className="roundedBorder">
         <div className="" style={{padding:"10px",display:"grid",gridTemplateColumns:"3fr 1fr",gridTemplateRows:"auto"}}>
             <div className="wide l2 r4 alignCenter">
                 <div className="wide">
             <div class="material-textfield">
                <input type="text" placeholder=" " style={{width:"100%"}}/>
                <label>Coupon code</label>
            </div>
            </div>
             </div>
                
             <div className="wide r2 l1">
                 <Button loading={applyLoading}  text="Apply" style="black" action={handleApply}/>
             </div>

             
         </div>
         
     </div>
 </div>
 </>
 :null}

 <div className="wide t4">
     <div className="title3">Payment</div>
 </div>
 <div className="wide t2">
     <div className="body2">All transactions are secure and encrypted</div>
 </div>
 <div className="wide t2">
     <div className="roundedBorder">
         <div className="checkoutLine" style={{padding:"10px"}}>
         <div className="flexStart myRadio">
         <input type="radio" id="card" name="card" checked={paymentType=="card"} onChange={handlePaymentType}/>
            <label for="card">Credit or debit card</label>
            
        </div>
         </div>
         {paymentType=="card"?<>
         <div className="wide r3 l3">
             <div className="wide">
                 <CheckoutForm userPack={props.userPack}/>
             </div>
     </div>
         </>
         :null}

     <div className="wide alignCenter t1 b1 l3 r3">
         <div className="hrElement">
         </div>
         </div>
         {/* <div className="checkoutLine" style={{padding:"10px"}}>
         <div className="flexStart myRadio">
         <input type="radio" id="card3" name="bank" onChange={handlePaymentType} checked={paymentType=="bank"}/>
            <label for="card3">Bank account</label>
            
        </div>
         </div>
        {paymentType=="bank"?
        <>         <div className="wide r2 l2">
        <BankPayment elements={props.elements} stripe={props.stripe} userPack={props.userPack}/>
    </div>
        </>:null} 
                 <div className="wide alignCenter b1 l3 r3">
         <div className="hrElement">
         </div>
         </div>
        */}


         <div className="checkoutLine" style={{padding:"10px"}}>
         <div className="flexStart myRadio">
         <input type="radio" id="card2" onChange={handlePaymentType} checked={paymentType=="paypal"} name="paypal"/>
            <label for="card2">Paypal</label>
            
        </div>
         </div>
 </div>

  </div>
            <div className="flexStart">
        <div class="md-checkbox alignCenter">
            <input name="keepUpdated" type="checkbox" onChange={props.handleCheckoutData}
           id="i2" defaultChecked={true}/>
            <label for="i2">Save payment information</label>
                       
                        </div>
    </div>
 <div className="wide alignCenter t1">
     <Button style="black" loading={payNowLoading} text="Pay now" action={handlePayNow}/>
 </div>
        </>
    )
}



