
import React from 'react';

export default function(props){
    if(props.type==="select"){
        return(
        <>
    <div className="select">
    <select className="select-text" required name={props.name} onChange={props.onChange} defaulValue={props.value}>
        {props.options.map((c,index)=>(
            index==0?
<option key={index} value="" disabled selected={props.selected}>{""}</option>
            :
            
            <option key={index} value={c.value} selected={props.value==c.label?true:false}>{c.label}</option>
        ))}
    </select>
    <label className="select-label">State</label>
    </div>
    
</>)}
    return(
        <>
        <div class="material-textfield">
        <input type={props.type} placeholder={props.placeholder?props.placeholder:" "} name={props.name} onChange={props.onChange} value={props.value}/>
        <label>{props.label}</label>
        </div>
        </>
    )
}



