

import React from 'react';
import Button from '../SvgElements/Button';
import CloseIcon from '../SvgElements/CloseIcon';
import Modal from './Modal';
import Select from 'react-select'

const options = [
  { value: 0, label: 'USPS First Class (Free)',status:'Free',estDeliveryEarly:7,estDeliveryLate:10},
  { value: 7.88, label: 'UPS Priority ($7.88)',status:'Premium',estDeliveryEarly:4,estDeliveryLate:7},
  { value: 14.88, label: 'FedEx Air Express ($14.88)',status:'Express',estDeliveryEarly:3,estDeliveryLate:5}
]

const token = window.localStorage.getItem('token')

const DeliveryOps = (props) => {
    const handleChange = (e)=>{
        console.log("uno de los malditos finales mas hermosos",e.value)
        props.setDeliveryMethod(e)
    }

    return(<Select options={options} defaultValue={'free'} onChange={handleChange}  defaultMenuIsOpen={true}/>)
}

const QuantityModal =(props)=>{
    
    return(<>
                    <div className="alignCenter" style={{position:"absolute",bottom:"0px",height:"50px",width:"100%"}}>
                    <Button text="Continue" action={props.userPack.methods.checkoutFunction}/>
                </div>
                <div className="flexStart t2">
                    <div className="flexStart l3">
                    <CloseIcon action={props.userPack.methods.popUpSwitch}/>
                    </div>
                    <div className="wide alignCenter r4">
                    <div className="title2">{props.userPack.methods.capitalize(props.userPack.modal.type)}</div>
                    </div>
                    
                    </div>
                    <div className="wide alignCenter" style={{height:"70%"}}>
                    <fieldset className="material">
		<input autoFocus={true} type="number" value={props.userPack.elements.order.selectionList[0].quantity} onChange={props.valueHandling} name={props.userPack.modal.type} autoComplete="off" required />
		<hr/>
		<label>Quantity</label>
	</fieldset>

                    </div>
                    <div>
                </div>
    </>)
}
const DeleteConfirmation =(props)=>{

    return(<>
                    <div className="alignCenter" style={{position:"absolute",bottom:"0px",height:"50px",width:"100%"}}>
                        <div className="wide half">
                            <div className="alignCenter">
                            <Button text="Cancel" action={props.userPack.methods.popUpSwitch}/>
                            </div>
                            <div className="alignCenter">
                            <Button text="Delete" action={props.executeDelete}/>
                                </div>
                           
                        </div>
                    
                </div>
                <div className="flexStart t2">
                    <div className="flexStart l3">
                    <CloseIcon action={props.userPack.methods.popUpSwitch}/>
                    </div>
                    <div className="wide alignCenter r4">
                    <div className="title2">Delete confirmation</div>
                    </div>
                    
                    </div>
                    <div className="wide absolute t4" style={{paddingTop:"60px",paddingLeft:"20px",height:"200px !important"}}>
                        <div className="wide">
                        <div className="body1">Are you sure you would like to delete this item?</div>
                        </div>
                        

                    </div>
                    <div>
                </div>
    </>)
}

const DeliveryMethodModal =(props)=>{
    const [deliveryMethod,setDeliveryMethod]=React.useState(props.userPack.elements.order.deliveryMethod)
    const handleDelivery=()=>{
        let neoOrder ={...props.userPack.elements.order}
        neoOrder.deliveryMethod=deliveryMethod
        props.userPack.methods.stateHandling('order',neoOrder)
        props.userPack.methods.checkoutFunction()
        // props.userPack.refs.routeCheckoutRef.current.click()
        return
    }
    return(<>
                    <div className="alignCenter" style={{position:"absolute",bottom:"0px",height:"50px",width:"100%"}}>
                    <Button text="Save" action={handleDelivery}/>
                </div>
                <div className="flexStart t2">
                    <div className="flexStart l3">
                    <CloseIcon action={props.userPack.methods.popUpSwitch}/>
                    </div>
                    <div className="wide alignCenter r4">
                    <div className="title2">Choose delivery method</div>
                    </div>
                    
                    </div>
                    <div className="wide alignCenter" style={{height:"70%"}}>
                        <div className="" style={{width:"80%"}}>
                        <DeliveryOps setDeliveryMethod={setDeliveryMethod}/>
                        </div>
                    

                    </div>
                    <div>
                </div>
    </>)
}
export default function(props){
    
    const valueHandling=(e)=>{
        let neoOrder={...props.userPack.elements.order}
        if(e.target.name=="quantity"&&e.target.value<1){
            return
        }
        
        neoOrder.selectionList[0][e.target.name]=e.target.value
        props.userPack.methods.stateHandling('order',neoOrder)
    }
    const executeDelete=()=>{
        props.userPack.methods.axios.defaults.headers.delete['Authorization']="JWT "+token
        console.log("tamo asqui atra",props.userPack.modal)
        if(props.userPack.modal.addressId){
            console.log("tamo asqui",props.userPack.modal.addressId)
            props.userPack.methods.axios.post(props.userPack.axiosPack.baseUrl+'deleteAddress/',{addressId:props.userPack.modal.addressId})
            let neoUser={...props.userPack.user}
            neoUser.associatedAddresses = neoUser.associatedAddresses.filter(c=>(c.id!=props.userPack.modal.addressId))
            props.userPack.methods.stateHandling('user',neoUser)
            props.userPack.methods.popUpSwitch()
        }else if(props.userPack.modal.cardId){
            console.log("tamo alante",props.userPack.modal.cardId)
            props.userPack.methods.axios.post(props.userPack.axiosPack.baseUrl+'deleteCard/',{cardId:props.userPack.modal.cardId})
            let neoUser={...props.userPack.user}
            neoUser.cards = neoUser.cards.filter(c=>(c.id!=props.userPack.modal.cardId))
            props.userPack.methods.stateHandling('user',neoUser)
            props.userPack.methods.popUpSwitch()
        }
        props.userPack.methods.getUser()
    }
    return(
        <>
        <Modal close={props.userPack.methods.popUpSwitch}>
            <div className="alignCenter" style={{width:props.userPack.dimensions.width+"px",height:props.userPack.dimensions.height}}>
                <div className="popUpCard">
                    {props.userPack.modal.type==="quantity"?
                    <QuantityModal userPack={props.userPack} valueHandling={valueHandling}/>
                    :null}
                                        {props.userPack.modal.type==="deliveryMethod"?
                    <DeliveryMethodModal userPack={props.userPack} valueHandling={valueHandling}/>
                    :null}
 {props.userPack.modal.type==="deleteConfirmation"?
                    <DeleteConfirmation userPack={props.userPack} executeDelete={executeDelete}/>
                    :null}

                </div>
            </div>

        </Modal>
        </>
    )
}



