import './media/css/neoTypography.css';
import './media/css/neoElements.css';
import './media/css/effects.css';
import './media/css/effects.scss';
import './media/css/bootstrap-grid.css';
import React, { Component } from 'react';

import {Link, Redirect, Router} from '@reach/router';
import Landing from './Components/Landing';
import Shop from './Components/Shop';
import Product from './Components/Product';
import Checkout from './Components/Checkout';
import Cart from './Components/Cart';
import Help from './Components/Landing';
import OrderHistory from './Components/OrderHistory';
import MobileLogin from './Components/MobileLogin';
import Order from './Components/Order';
import Account from './Components/Account';
import moment from 'moment';
import PaymentOptions from './Components/PaymentOptions';
import UserId from './Components/UserId';
import ShippingInfo from './Components/ShippingInfo';
import NewCard from './Components/NewCard';
import ShippingAddresses from './Components/ShippingAddresses';
import ModalHandler from './Components/ModalHandler';
import {loadStripe} from '@stripe/stripe-js';
import {ElementsConsumer} from '@stripe/react-stripe-js';
import {
  Elements
} from "@stripe/react-stripe-js";

const stripePromise = loadStripe(
    "pk_test_51HWiqaEs4YXxlTlMm9eqb2HE2yQ42HiojQXfFfgv6KmztJJr7hIg4upzHVxKp9bZrVrGH4K8HlyszlYiXiTPBDdr00mfEFmgVI"
  );

const w = window;
const d = document;
const documentElement = d.documentElement;
const body = d.getElementsByTagName('body')[0];

//      const width = props.userPack.dimensions.width
// const height = w.innerHeight || documentElement.clientHeight || body.clientHeight;
  const getUrl = window.location;
 
  let baseUrl = "https://nu94mzwda7.execute-api.us-east-2.amazonaws.com/dev/store/";
  let baseWss = "https://nu94mzwda7.execute-api.us-east-2.amazonaws.com/dev/";

  if(getUrl.host.includes(":")){
    baseUrl = "http://10.0.0.249:8080/store/";
  }
  const axios = require('axios');
  axios.defaults.xsrfCookieName = 'csrftoken';
  axios.defaults.xsrfHeaderName = 'X-CSRFToken';
  axios.defaults.withCredentials = false;
  

  const currencySymbols = [
    {
      value: 'USD',
      label: 'US$',
    },
    {
      value: 'DOP',
      label: 'RD$',
    },
    {
      value: 'EUR',
      label: '€',
    },
  
  ];
  const capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
  }
  function formatNumber(num) {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  }
  function validateEmail(email) {
    var re = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    return re.test(String(email).toLowerCase());
  }
  
  class App extends Component {
    state = { loginModal:{active:false,firstName:'',social:false,lastName:'',phoneNumber:'',login:null,
    username:'',password:'',innerLoading:false,innerError:null,type:""},
    order:{userName:"", selectionList:[{price:34.99,quantity:1}],deliveryMethod:{ value: 0, label: 'USPS First Class (Free)',status:'Free',estDeliveryEarly:7,estDeliveryLate:10},
      card:null,paypal:null,bitcoin:null,shippingAddress:null,billingAddress:null},
      shippingAddress:null,stripeObj:{},stripeEls:{},stripeCustomer:{paymentMethods:[]},
    ulteriorMotive:{active:false},modal:{open:false,type:''},stripeElements:{},
    shoppingList:[{quantity:1,itemId:1,price:34.99,shipping:null}],shippingInfo:{useAccountName:true,useSameShipping:true,keepUpdated:true},
    selected:null,logged:false,currency:{symbols:currencySymbols,navColor:"white",
      rates:{"USD":1,"AED":3.672058,"ARS":60.073152,"AUD":1.462619,"BGN":1.772324,"BRL":4.175311,"BSD":1,"CAD":1.313949,"CHF":0.970542,"CLP":775.032232,"CNY":6.937035,"COP":3356.26087,"CZK":22.774105,"DKK":6.769282,"DOP":53.200551,"EGP":15.731404,"EUR":0.906153,"FJD":2.173377,"GBP":0.763843,"GTQ":7.686349,"HKD":7.772394,"HRK":6.743727,"HUF":304.680441,"IDR":13481.299912,"ILS":3.454776,"INR":71.322065,"ISK":124.593825,"JPY":109.43859,"KRW":1168.893381,"KZT":378.401961,"MXN":18.789109,"MYR":4.06712,"NOK":9.020184,"NZD":1.513791,"PAB":1,"PEN":3.322153,"PHP":50.850544,"PKR":154.697395,"PLN":3.850458,"PYG":6432.833333,"RON":4.330082,"RUB":61.891661,"SAR":3.750937,"SEK":9.552551,"SGD":1.351475,"THB":30.561937,"TRY":5.940419,"TWD":30.040088,"UAH":24.306582,"UYU":37.255792,"ZAR":14.392269},selected:"DOP"},
            axiosPack:{axios,baseUrl,baseWss},rawResults:[],
              user:null,rent:false,dimensions:{isMobile:null,width:null,height:null,scrollValue:0}
     
            }  
     stateHandling=(tag,latter)=>{
       this.setState({[tag]:latter})
       console.log(tag,latter)
       console.log("handled")
     }
     neoSelected=(element)=>{
       this.setState({selected:element})
     }
     processKey=(e)=>{
       console.log("inHere")
       if(e.code==="Escape" && this.state.modal.open){
          let newVal = {...this.state.modal}
          newVal.open=false
          this.setState({modal:newVal})
       }
       if((e.code==="Enter" || e.code==="NumpadEnter")&& ((this.state.modal.open && this.state.modal.type==="userId") || (window.location.href.includes("login")|| window.location.href.includes("register")|| window.location.href.includes("userId")))){
         if(window.location.href.includes("login")){
          this.login(this.state.loginModal.username,this.state.loginModal.password)
         }else(
          this.handleRegister()
         )
        
     }
     }
     handleScroll=()=>{
      let newDimensions={...this.state.dimensions}
      // newDimensions.scrollValue-window.scrollY>=1
      if(newDimensions.scrollValue!==window.scrollY){
        newDimensions.scrollValue=window.scrollY

        const val = this.state.dimensions.scrollValue
      const that = this
      
      try {
        // let navBar=document.querySelector('#root > div.mobileNav')
  
  let mobileSearchBar=document.querySelector('#root > div:nth-child(2) > div.mobileSearchBar')
  let bottomNavBar=document.querySelector('#root > div.bottomNavbar2')
  let indexNavBar=document.querySelector('#root > div:nth-child(2) > div.indexNavBar')
  
        // if(window.location.href.includes('loginMobile')){
        //   navBar.style.display='none'
        // }else{
        //   navBar.style.display='flex'
        // }
  
        if(val<that.state.dimensions.height*0.85&&that.state.dimensions.width<759){
          that.setState({navColor:"white"})
          mobileSearchBar.style.display="none !important"
          mobileSearchBar.style.position="absolute"
          mobileSearchBar.style.top="-81px"
          console.log("set")
          bottomNavBar.style.display="none !important"
          bottomNavBar.style.position="fixed"
          bottomNavBar.style.bottom="-81px"
          indexNavBar.classList.remove("blackNavBar") 
          indexNavBar.classList.add("transparentNavBar")
          
          
          
          return
        }
        if(val>that.state.dimensions.height*0.85&&that.state.dimensions.width<759){
          mobileSearchBar.style.display="flex"
          bottomNavBar.style.display="grid"
          mobileSearchBar.style.position="fixed"
          mobileSearchBar.style.top="0px"
          return
        }
        if(val>that.state.dimensions.height*0.23){
          that.setState({navColor:"black"})
          indexNavBar.classList.remove("transparentNavBar")
          indexNavBar.classList.add("blackNavBar")
  
        } else{
          that.setState({navColor:"white"})
          indexNavBar.classList.remove("blackNavBar") 
          indexNavBar.classList.add("transparentNavBar")
          
        }
        // if(val>30){
        //   const alertEl=document.querySelector('#root > div > div.mobileNav > div.alertBg')
        //   alertEl.style.top=-70+'px'
        //   const mobileSearchBar=document.querySelector('#root > div.mobileNav > div.mobileSearchBar')
        //   mobileSearchBar.style.top=0+'px'
        // }
      } catch (error) {
      }


        this.setState({dimensions:newDimensions})
      }
  
    }
    updateDimensions=()=>{
      const w = window;
      const d = document;
      const documentElement = d.documentElement;
      const body = d.getElementsByTagName('body')[0];
      const width = w.innerWidth || documentElement.clientWidth || body.clientWidth;
      const height = w.innerHeight || documentElement.clientHeight || body.clientHeight;
      if (width < 769){
        const newDimensions={width:documentElement.clientWidth, height:height, isMobile:true}
        this.setState({dimensions:newDimensions})
        return
      }
      const newDimensions={width:documentElement.clientWidth, height:height-100, isMobile:false}
      this.setState({dimensions:newDimensions})
    }
    updateVals=(prevProps)=>{
      return
    }
    constructor(props){
      super(props)
      //this.getUser()
      
      this.routeCheckoutRef= React.createRef();
      this.routeShippingAddressRef= React.createRef();
      this.routePaymetOptionsRef= React.createRef();
      this.routeLandingRef= React.createRef();
      this.routeUserIdRef= React.createRef();
      this.routeNewCardRef= React.createRef();

    }
    componentDidUpdate(prevProps){
      this.updateVals(prevProps);
    }
  
    handleRegister = (data=false)=>{
      console.log("called")
      let neoModal = {...this.state.loginModal}
      neoModal.innerLoading=true
      this.setState({loginModal:neoModal})
      const that = this
      async function register (){
        let sendingData
        if(data){
          sendingData=data
        }else{
          sendingData=data={}
        }
        let myUsername = that.state.loginModal.username
        let myPassword = that.state.loginModal.password
        sendingData.username=myUsername
        sendingData.password=myPassword
  
        sendingData.phoneNumber=that.state.loginModal.phoneNumber
        let social = that.state.loginModal.social
  
        const isEmail = await validateEmail(myUsername)
        if(!isEmail){
          neoModal.innerLoading=false
          that.setState({loginModal:neoModal})
          return
        }
        if(myPassword.length<8){
          neoModal.innerLoading=false
          that.setState({loginModal:neoModal})
          return
        }
        if(social){
          axios.post(baseUrl+"custom-auth/",sendingData)
          .then(res=>{
            console.log(res.data, "logging you in 1 ...")
            that.systemLogin(res.data.user,res.data.token)
            neoModal.innerLoading=false
            neoModal.social=false
            neoModal.active=false
            that.setState({loginModal:neoModal})
            if(window.location.href.includes('/loginMobile')){
              // console.log("ta incluyendo")
              try{that.routeProfileRef.current.click()}catch (err){console.log(err)}
              return
          }else{
            // console.log("no ta incluyendo",window.location.href)
          }}
            ).catch(err=>{
              neoModal.innerLoading=false
              neoModal.innerError="error post info fetching"
              neoModal.social=false
              that.setState({loginModal:neoModal})
              console.log("hay bosbo")
              return
                })
        }else{
          sendingData.first_name=capitalize(that.state.loginModal.firstName)
          sendingData.last_name=capitalize(that.state.loginModal.lastName)
          sendingData.name=sendingData.first_name+" "+sendingData.last_name
          await axios.post(baseUrl+"api/existing/",sendingData)
          .then(res=>{
            if(res.data.message==="existe"){
              that.login(sendingData.username,sendingData.password)
              return
                    }
                    else{
                      that.login(sendingData.username,sendingData.password)
                      return
                    }
            },err=>{
              
            neoModal.innerLoading=false
            neoModal.innerError="Este correo ya existe, intente iniciar sesión"
            that.setState({loginModal:neoModal})
          })
          
        }
  
  
        //window.location.href = ("https://carly.do")
      }
      register()
    }
  
    login = (username,password) =>{
      
      let neoModal = {...this.state.loginModal}
      neoModal.innerLoading=true
      neoModal.innerError=null
      this.setState({loginModal:neoModal})
      axios.post(baseUrl+"token-auth/",{username,password})
      .then(res=>{
        console.log("success")
          this.systemLogin(res.data.user,res.data.token)
          let neoModal = {...this.state.loginModal}
          neoModal.innerLoading=false
          neoModal.active=false
          this.setState({loginModal:neoModal})
          if(window.location.href.includes('/loginMobile')){
              // console.log("ta incluyendo")
              try{this.routeProfileRef.current.click()}catch (err){console.log(err)}
          }else{
            // console.log("no ta incluyendo",window.location.href)
          }
          return
      },
      error=>{
        
        console.log("hay bobooo")
        let neoModal = {...this.state.loginModal}
        neoModal.innerLoading=false
        neoModal.innerError="Usuario y/o contraseña incorrecta"
        this.setState({loginModal:neoModal})
          return false
  })}
  systemLogin=(user,token)=>{
      window.localStorage.setItem('token',token);
      window.localStorage.setItem('userId',user.id);
    
      // let notifications = [].concat(user.activeLeasing).concat(user.activeRenting)
      // //console.log("estas iban a ser las notfs", notifications)
      // const notificationClient = this.openNotifications(user.id)
      const newSwitch = {...this.state.loginModal}
      newSwitch.active=false
      console.log(user)
      let myInfo = {...this.state.shippingInfo}
      let neoAdd = user.associatedAddresses.filter(c=>c.id==user.defaultAddressId)[0]
      myInfo.fullName=user.name
      try {
        myInfo.address1=neoAdd.address1
        myInfo.address2=neoAdd.address2
        myInfo.city=neoAdd.city
        myInfo.state=neoAdd.state
        myInfo.zipcode=neoAdd.zipcode
        myInfo.phoneNumber=neoAdd.phoneNumber
      } catch (error) {
        try {
          neoAdd = user.associatedAddresses[0]
          user.defaultAddressId=neoAdd.id
          myInfo.address1=neoAdd.address1
          myInfo.address2=neoAdd.address2
          myInfo.city=neoAdd.city
          myInfo.state=neoAdd.state
          myInfo.zipcode=neoAdd.zipcode
          myInfo.phoneNumber=neoAdd.phoneNumber
        } catch (error) {
          
        }
  
      }
  
      let neoOrder={...this.state.order}
      if(user.cards.length>0){
        let cardy
        try {
          cardy=user.cards.filter(c=>c.id==user.defaultPaymentId)[0]
          console.log(cardy.cardNumber)
        } catch (error) {
          cardy=user.cards[0]
        }
        neoOrder.card=cardy
        user.defaultPaymentId=cardy.id
      }
      
      this.setState({order:neoOrder,shippingAddress:true,shippingInfo:myInfo,user,fetchedUser:true,logged:true,loginModal:newSwitch});
  
    //   if(window.location.href.includes('/loginMobile')){
    //     // console.log("ta incluyendo")
    //     try{this.routeHomeRef.current.click()}catch (err){console.log(err)}
    // }
      
    if(this.state.ulteriorMotive.active){
      if(this.state.ulteriorMotive.route==="checkout"){
        this.checkoutFunction()
      }
    }
  
  }
  
  selectStep=(step)=>{
    let currentStep = {...this.state.steps}
    currentStep.selectedStep=step
    this.setState({steps:currentStep});
  }
  logout = () =>{
      window.localStorage.removeItem('token');
      window.localStorage.removeItem('userId');
  
  this.setState({fetchedUser:false,logged:false,user:null,selected:'home'})
  try{this.routeHomeRef.current.click()}catch (err){console.log(err)}
  
  
  
  
  }
  deleteListing=(id)=>{
    this.virtualDeleteListing(id)
  
    this.state.myListing.listingPictures.forEach( picture => {
      axios.delete(picture.url)
    });
    const token = window.localStorage.getItem('token')
    axios.defaults.headers.delete['Authorization']="JWT "+token
    axios.delete(baseUrl + `api/listings/`+id).then(resp=>{
      //this.reFetchUser()
    })
    
  }
  
 getUser=(latitude,longitude)=>{
  const token = window.localStorage.getItem('token')

  // console.log("hola chicos, no hagan drogas",firstName,zipzip,lastName,address1,state,city)
  let userRequest=async function(){return null}
  let neoAxios ={...axios}
  if(token){
    console.log(token)
    axios.defaults.headers.get['Authorization']="JWT "+token
    userRequest = axios.get(baseUrl + `current_user/`)
  }
  axios.defaults.headers.get['Content-Type']='application/x-www-form-urlencoded'
  // const featuresRequest = axios.get(baseUrl +"getFeatures/")
  // const statesRequest = axios.get(baseUrl +"getStates/")
  // const countriesRequest = axios.get(baseUrl +"getCountries/")
  // const results = axios.post(baseUrl+"getResults/",{"locationCode":null})
  
  // const featuresRequest = axios.get(baseUrl +"getFeatures/")
  
  neoAxios.defaults.proxy = {host:"https://cors-anywhere.herokuapp.com"}
  delete neoAxios.defaults.headers.get['Authorization'];
  const currenciesReq = neoAxios.get("https://api.exchangerate-api.com/v4/latest/USD")
  Promise.all([userRequest,currenciesReq,]).then(res => {
    const data = res[0].data;
    // const rawResults=res[2].data;
    const currenciesData=res[1].data;
    console.log(currenciesData,"data currencies")
    let newSteps = {...this.state.steps}
    if(data){
      let newListings = []
      data.listings.forEach(listing => {
        // const rate = this.state.currency.rates[this.state.currency.selected]
        // listing.dailyPrice = listing.dailyPrice*rate
        // listing.weeklyPrice = listing.weeklyPrice*rate
        newListings.push(listing)
      }); 
      data.listings = newListings
    }

    // if(data.is_superuser){this.fetchSupportChats()}
    let switcher;
    let neoCurrency = {...this.state.currency}
    neoCurrency.rates=currenciesData.rates
    data?switcher=true:switcher=false

    let myInfo = {...this.state.shippingInfo}
    let neoAdd = data.associatedAddresses.filter(c=>c.id==data.defaultAddressId)[0]
    myInfo.fullName=data.name
    // myInfo.firstName=firstName
    // myInfo.lastName=lastName
    // myInfo.city=city
    // myInfo.state=state
    // myInfo.zipcode=zipzip
    // myInfo.address1=address1
    // myInfo.address2=address2

    try {
      myInfo.address1=neoAdd.address1
      myInfo.address2=neoAdd.address2
      myInfo.city=neoAdd.city
      myInfo.state=neoAdd.state
      myInfo.zipcode=neoAdd.zipcode
      myInfo.phoneNumber=neoAdd.phoneNumber
    } catch (error) {
      try {
        neoAdd = data.associatedAddresses[0]
        data.defaultAddressId=neoAdd.id
        myInfo.address1=neoAdd.address1
        myInfo.address2=neoAdd.address2
        myInfo.city=neoAdd.city
        myInfo.state=neoAdd.state
        myInfo.zipcode=neoAdd.zipcode
        myInfo.phoneNumber=neoAdd.phoneNumber
      } catch (error) {
        
      }

    }

    let neoOrder={...this.state.order}
    if(data.cards.length>0){
      let cardy
      try {
        cardy=data.cards.filter(c=>c.id==data.defaultPaymentId)[0]
      } catch (error) {
        cardy=data.cards[0]
      }
      try {
        neoOrder.card=cardy
        data.defaultPaymentId=cardy.id
      } catch (error) {
      }

    }
    
    let stripeCustomer=null
    let resss = null
    this.setState({user:data,latitude,longitude},async()=>{
      await axios.post(baseUrl+'getStripeCustomer/',{customerId:data.stripeCustomerId}).then(res=>{
        resss=res.data
        stripeCustomer={...resss.stripeCustomer}
        stripeCustomer.paymentMethods = resss.paymentMethods.data
      }).catch(err=>{console.log("bobo",err)})
      this.setState({stripeCustomer,order:neoOrder,
        fetchedUser:switcher,shippingAddress:true,shippingInfo:myInfo,
        logged:switcher,currency:neoCurrency,
        dialogFetching:false,steps:newSteps})
      
      /*
      const rates = res[1].data.rates
      let currency = {...this.state.currency}
      currency.rate = rates[currency.preferred]
      currency.rates = {"DOP":rates["DOP"],"EUR":rates["EUR"]}
      this.setState({currency:currency})
      */
    })
  }).catch(error=>{console.log("error getting user",error)})
 }
  fetchElements=()=>{
  delete axios.defaults.headers.get['Authorization'];
  axios.defaults.headers.get['Content-Type']='application/x-www-form-urlencoded'
  const featuresRequest = axios.get(baseUrl +"getFeatures/")
  const statesRequest = axios.get(baseUrl +"getStates/")
  const countriesRequest = axios.get(baseUrl +"getCountries/")
  const myBooking =this.state.myBooking
  let endDate
  let startDate
  if(myBooking.endDate===null || myBooking.length<9){
    endDate=moment().add(7,'d')
  }else{
    endDate=moment(myBooking.endDate)
  }
  if(myBooking.startDate===null || myBooking.length<9){
    startDate=moment()
  }else{
    startDate=moment(myBooking.startDate)
  }
  let counter=0
  
  Promise.all([statesRequest,countriesRequest,featuresRequest]).then(res => {
    const features = res[3].data;
    const states = res[0].data;
    const countries = res[1].data;
    const results = res[2].data;
  
    //console.log("fetched data", states, countries,"resuts",results)
    this.setState({elements:{features:features,states:states,countries:countries}})
    this.setState({rawResults:results})
    counter=counter+1
    if(counter>1){
      counter=-1
      this.setState({fetched:true})
    }
  })
  axios.defaults.proxy = {host:"https://cors-anywhere.herokuapp.com"}
  axios.get("https://api.exchangerate-api.com/v4/latest/USD").then(
    resp=>{
      let currency={selected:"DOP",rates:resp.data.rates,symbols:currencySymbols}
      //console.log("respuesta covnersion monedas",currency)
      this.setState({currency:currency})
      counter=counter+1
      if(counter>1){
        counter=-1
        this.setState({fetched:true})
      }
      axios.defaults.proxy = null
    }
  ).catch(err=>{
    console.log("error con api monedas",err)
    console.log("using mock up data")
    let currency={selected:"DOP",symbols:currencySymbols,rates:{"USD":1,"AED":3.672058,"ARS":60.073152,"AUD":1.462619,"BGN":1.772324,"BRL":4.175311,"BSD":1,"CAD":1.313949,"CHF":0.970542,"CLP":775.032232,"CNY":6.937035,"COP":3356.26087,"CZK":22.774105,"DKK":6.769282,"DOP":53.200551,"EGP":15.731404,"EUR":0.906153,"FJD":2.173377,"GBP":0.763843,"GTQ":7.686349,"HKD":7.772394,"HRK":6.743727,"HUF":304.680441,"IDR":13481.299912,"ILS":3.454776,"INR":71.322065,"ISK":124.593825,"JPY":109.43859,"KRW":1168.893381,"KZT":378.401961,"MXN":18.789109,"MYR":4.06712,"NOK":9.020184,"NZD":1.513791,"PAB":1,"PEN":3.322153,"PHP":50.850544,"PKR":154.697395,"PLN":3.850458,"PYG":6432.833333,"RON":4.330082,"RUB":61.891661,"SAR":3.750937,"SEK":9.552551,"SGD":1.351475,"THB":30.561937,"TRY":5.940419,"TWD":30.040088,"UAH":24.306582,"UYU":37.255792,"ZAR":14.392269}}
    this.setState({currency:currency})
    counter=counter+1
    if(counter>1){
      counter=-1
      this.setState({fetched:true})
    }
    axios.defaults.proxy = null
  })
  }

     componentDidMount(){
    //   const stripePromise = loadStripe(
    //     "pk_test_6pRNASCoBOKtIshFeQd4XMUh"
    // );
    // const stripe = useStripe();
    // const stripeEls = useElements();
    //     this.setState({stripeElements:{stripePromise,stripe,stripeEls}})
       const that = this
       let latitude
       let longitude
       async function test(){
        const firstName = window.localStorage.getItem('firstName')
        const lastName = window.localStorage.getItem('lastName')
        const address1 = window.localStorage.getItem('address1')
        const address2 = window.localStorage.getItem('address2')
        const city = window.localStorage.getItem('city')
        const state = window.localStorage.getItem('state')
        const zipzip = window.localStorage.getItem('zipcode')
        let neoState = {...that.state.shippingInfo}
        neoState.firstName=firstName
        neoState.lastName=lastName
        neoState.address1=address1
        neoState.address2=address2
        neoState.city=city
        neoState.state=state
        neoState.zipcode=zipzip
        that.setState({shippingInfo:neoState})
         await that.updateDimensions()
        await navigator.geolocation.getCurrentPosition(
          position => {
            latitude= position.coords.latitude
            longitude= position.coords.longitude
          }, 
          err => {
              latitude= 18.4637770
              longitude= -69.9336969
            console.log("error ubicando cliente",err)}
        );
        that.getUser(latitude,latitude)
       }
       test()
      
      //this.updateDimensions()
      document.querySelector('html').addEventListener('keydown',this.processKey)
      window.addEventListener('scroll',this.handleScroll)
      window.addEventListener("resize", this.updateDimensions);
     }
     componentWillUnmount(){
      document.querySelector('html').removeEventListener('keydown',this.processKey)
      window.removeEventListener('scroll',this.handleScroll)
      window.removeEventListener('resize',this.updateDimensions)
    }
     switchLoginModal=(newModal)=>{
       try {
        let navElement=document.querySelector('#root > div > div.navBar');
        if(newModal.active){
          
          navElement.style.position="relative"
        }else{
         navElement.style.position="fixed"
        }
       } catch (error) {
         //pass
       }
      
       this.setState({loginModal:newModal})
     }
     switchRent=(e,f=null)=>{
      let prevVal
      if(f){
        prevVal= true
      }else{
        if(!f){
          prevVal= false
        }else{
          prevVal= !this.state.rent
        }
        
      }
      this.setState({rent:prevVal})
     }
    closeAll=()=>{
      this.setState({modal:{open:false,type:''}})
    }
    checkoutFunction=(e)=>{
      try {
        e.stopPropagation()
        e.preventDefault()
      } catch (error) {
        
      }

      this.setState({ulteriorMotive:{active:true,route:"checkout"}})

      if(this.state.shippingAddress && (this.state.shippingInfo.address1&&this.state.shippingInfo.zipcode&&this.state.shippingInfo.state)){
        
        if(this.state.user && this.state.user.cards.length>0){
          this.routeCheckoutRef.current.click()
        }else{
          this.routeCheckoutRef.current.click()
        }
      }else{
        this.routeCheckoutRef.current.click()
      }
        
      
      this.closeAll()
      
    }
    popUpSwitch=(e)=>{
      try {
        e.preventDefault()
        e.stopPropagation()
      } catch (error) {
        //
      }

      let neoModal={...this.state.modal}
      neoModal.open=!neoModal.open
      this.stateHandling('modal',neoModal)
  }
  valueHandling=(e)=>{

  }
    render() {
      
      const shopData={paypal:this.state.paypal,card:this.state.card,bitcoin:this.state.bitcoin}
      const methods={getUser:this.getUser,stateHandling:this.stateHandling,checkoutFunction:this.checkoutFunction,
        popUpSwitch:this.popUpSwitch,capitalize,axios,baseUrl,
        login:this.login,logout:this.logout,formatNumber,neoSelected:this.neoSelected,
        handleRegister:this.handleRegister}
      const refs={routeCheckoutRef:this.routeCheckoutRef,routeLandingRef:this.routeLandingRef,
        routePaymetOptionsRef:this.routePaymetOptionsRef,routeUserIdRef:this.routeUserIdRef,routeNewCardRef:this.routeNewCardRef}
      const elements={
        ulteriorMotive:this.state.ulteriorMotive,order:this.state.order,shippingAddress:this.state.shippingAddress,
        billingAddress:this.state.billingAddress
      }
      const userPack={refs,stripeElements:this.state.stripeElements,order:this.state.order,
        stripeObj:this.state.stripeObj,stripeEls:this.state.stripeEls,stripeCustomer:this.state.stripeCustomer,
        elements,user:this.state.user,axiosPack:this.state.axiosPack,latitude:this.state.latitude,
       shippingInfo:this.state.shippingInfo,shopData:shopData,modal:this.state.modal,w,documentElement,body,
      longitude:this.state.longitude, dimensions:this.state.dimensions,navColor:this.state.navColor,
        loginModal:this.state.loginModal,methods:methods,selected:this.state.selected,logged:this.state.logged,
        rent:this.state.rent,switchRent:this.switchRent,currency:this.state.currency,rawResults:this.state.rawResults,
        switchLoginModal:this.switchLoginModal}
      return ( 
      <>
              {this.state.modal.open?
        <>
        
        <ModalHandler userPack={userPack}/>
        </>:null}
        <Elements stripe={stripePromise}>
    <ElementsConsumer>
    {({stripe, elements}) => (
      <>
       <Router>
          
          <Landing path="/" userPack={userPack}/>
          <Shop path="/products" userPack={userPack}/>
          <Product path="/products/:productId" userPack={userPack}/>
          {["/checkout", "/checkout/Information","/checkout/Shipping","/checkout/Payment"].map((page) => (
        <Checkout tabs={['Information','Shipping','Payment']} stripe={stripe} elements={elements} path={page} userPack={userPack}/>
      ))}
          
          <Cart path="/cart" userPack={userPack}/>
          <Help path="/help" userPack={userPack}/>
          <Account path="/account" userPack={userPack}/>
          {["/shippingInfo", "/checkout/shippingInfo"].map((page) => (
        <ShippingInfo path={page} userPack={userPack}/>
      ))}
          {["/paymentOptions", "/checkout/paymentOptions"].map((page) => (
        <PaymentOptions path={page} userPack={userPack}/>
      ))}
                {["/paymentOptions/newCard", "/checkout/paymentOptions/newCard"].map((page) => (
                  
                <NewCard path={page} userPack={userPack} stripe={stripe} elements={elements}/>
      ))}
                {["/userId", "/login","/register",
                "checkout/login","checkout/register","checkout/userId",
                "checkout/info/login","checkout/info/register","checkout/info/userId",
                "checkout/shipping/login","checkout/shipping/register","checkout/shipping/userId",
                "checkout/payment/login","checkout/payment/register","checkout/payment/userId"].map((page) => (
        <UserId path={page} userPack={userPack}/>
      ))}
          <ShippingAddresses path={"/shippingAddresses"} userPack={userPack}/>
          <ShippingInfo path={"/shippingAddresses/:addressId"} userPack={userPack}/>
          <OrderHistory path="/orders" userPack={userPack}/>
          <Order path="/orders/:orderId" userPack={userPack}/>
          <MobileLogin path="/mobileLogin" userPack={userPack}/>
          
        </Router>
      </>

          )}
          </ElementsConsumer>
            </Elements>   
       
          <Link to="/checkout" ref={this.routeCheckoutRef}/>
          <Link to="checkout/paymentOptions" ref={this.routePaymetOptionsRef}/>
          <Link to="/checkout/shippingInfo" ref={this.routeShippingAddressRef}/>
          <Link to="/" ref={this.routeLandingRef}/>
          <Link to="/userId" ref={this.routeUserIdRef}/>
          <Link to="/checkout/paymentOptions/newCard" ref={this.routeNewCardRef}/>
      </>
     );
  }
}


export default App;
