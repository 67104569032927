import { Link } from '@reach/router';
import React from 'react';
import MobileNavBarPlain from '../generalComponents/MobileNavBarPlain';
import CheckMark from '../SvgElements/CheckMark';
import TrackPackageIcon from '../SvgElements/TrackPackageIcon';
import moment from 'moment'

export default function(props){
    const user=props.userPack.user
    const logout=()=>{
        props.userPack.methods.logout()
        props.userPack.refs.routeLandingRef.current.click()
        return
    }
    return(
        <>
        <MobileNavBarPlain title="My account"/>

        <div className="alignCenter">
        <div className="mainBody">
        
            {/* <div className="row t2">
            <div className="body1 grey1 r1">Sort by</div><div className="body1">Date</div>
       </div> */}
       {props.userPack.logged?
              <>
              <div className="wide">
        <div className="half">
            <div className="wide">
            <div className="wide">
       <div className="t4 title1">Hello {user.first_name}!</div></div>
       <div className="wide"><div className="t2 caption2 grey1">joined in {moment(props.userPack.user.date_joined).format('YYYY')}</div></div>
       <div className="t2 bold caption2 green2 button">Edit my account</div>
            </div>
            <div className="alignCenter">
            <div className="">
                <div className="row alignCenter">
                <div className="profilePicture">
                </div>
                </div>
                <div className="row">
                <div className="t2 bold caption2 green2 button">Cambiar foto</div>
                </div>
                </div>
                </div>
        </div>
       {(user.confirmedPhone||user.confirmedEmail)?
       <>
       <div className="wide t4 alignCenter">
           <div className="row alignCenter">
               {user.confirmedPhone?
                              <div className="">
                              <div className="container alignCenter">
                              <CheckMark/>
                          <div className="caption1 l2">Phone number</div>
                          </div></div>
               :null}
               {user.confirmedEmail?
               <div className="">
               <div className="container alignCenter l4">
               <CheckMark className=""/>
               <div className="caption1 l2">Email address</div>
               </div>
              </div>
               :null}
                </div>
               </div>
       </>
       :null}
       
       
       <div className="row alignCenter t1">
       </div> 
       <div className="wide t4">
       <div className="title1"><Link to="../shippingAddresses">Address book</Link></div>
       </div>
       <div className="wide t4">
       <div className="title1"><Link to="../paymentOptions">Payment methods</Link></div>
       </div>
       <div className="wide t4">
       <div className="title1"><Link to="../orders">Order history</Link></div>
       </div>
       <div className="wide t4">
       <div className="title1">0 Feedback</div>
       </div>
       <div className="wide t5 link" onClick={logout}>
       <div className="title2 blue1">Logout</div>
       </div>
       </div>

              </>
              :
       <>
       <div className="wide">
           <div className="title1">Please <span className="blue1"><Link to='../login'>login to your account</Link></span> or <span className="blue1"><Link to='../register' >sign up today!</Link></span></div>
       </div>
       </>
       }
               </div>
        </div>
        </>
    )
}



