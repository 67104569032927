
import { Link } from '@reach/router';
import React from 'react';

export default function(props){
    const action=(e)=>{
        props.action(e)
    }
    let color="white"
    let buttonName="button button1"
    let size="caption2"
    if(props.style){
        if(props.style==="2"){
            color=""
            buttonName="button buttonGrey"
            size="buttonGreyText"
        }else if(props.style==="3"){
            color="white"
            buttonName="button buttonRed"
            size="caption2"
        }
    else if(props.style==="4"){
        color="white"
        buttonName="button buttonHalf"
        size="caption2"
    }
    else if(props.style==="black"){
        color="white"
        buttonName="button buttonBlack"
        size="body2 bold"
    }
}
    return(
        <>
{props.action?
 <div className={buttonName+" alignCenter"} onClick={props.action?action:()=>{}}>
    <div onClick={props.action?action:()=>{}} disabled={false}
     id={props.buttonId?props.buttonId:""} 
     className={size+" bold "+buttonName+" alignCenter textAlign "+color}>
         <div className="wide ">
         {props.loading?<div className="spinner"/>:props.text}
</div></div>
</div>
:
<>
<div className="wide alignCenter">
<Link className="wide alignCenter" to={props.navigate?props.navigate:"../products"} >
<div className={buttonName+" alignCenter"} > 
<div disabled={false} id={props.buttonId?props.buttonId:""} 
className={size+" bold textAlign "+color}>{props.loading?<div className="spinner"/>:props.text}
</div>
</div>
</Link></div>
</>
}
        </>
    )
}



