import React from 'react';
import Button from '../SvgElements/Button';

export default function(props){
    return(
        <>
        <div className="wide roundedBorder" style={{padding:"16px"}}>
            <div className="half">
                <div className="flexStart">
                <div className="body2 grey2"> Contact</div>
                </div>
                <div className="flexEnd">
                <div className="body3 button" onClick={props.goInfo}> Change</div>
                </div>
                
            </div>
           <div className="wide t1">
               <div className="body2">{props.userPack.logged?props.userPack.user.username:props.userPack.order?props.userPack.order.userName===""?"Please provide a valid email address":props.userPack.order.userName:"wait"}</div>
           </div>
           <div className="wide alignCenter">
               <div className="hrElement t3"></div>
           </div>
           <div className="half t3">
                <div className="flexStart">
                <div className="body2 grey2"> Shipping address</div>
                </div>
                <div className="flexEnd">
                <div className="body3 button" onClick={props.goInfo}> Change</div>
                </div>
                
            </div>
           <div className="wide t1">
               <div className="body2">{props.userPack.shippingInfo.address1&&props.userPack.shippingInfo.zipcode?props.userPack.shippingInfo.address1+", "+props.userPack.shippingInfo.city+", "+props.userPack.shippingInfo.state+" "+props.userPack.shippingInfo.zipcode+", United States":"Please instert a valid address"}</div>
           </div>
        </div>
        <div className="wide t4">
            <div className="title3">Shipping method</div>
        </div>
        <div className="wide t2">
            <div className="roundedBorder">
                <div className="checkoutLine" style={{padding:"10px"}}>
                <div className="flexStart myRadio">
                    <input type="radio" id="shippingMethod" name="shippingMethod" defaultChecked={true}/>
                        <label for="card">USPS First Class Package</label>
                        
                    </div>
                  
                    <div className="flexEnd" >
                    <div className="body1 bold">$5.00</div>

                    </div>
                    
                </div>
                
            </div>
        </div>
        <div className="wide alignCenter t3">
            <Button text="Continue to payment" style="black" navigate={"/checkout/"+props.tabs[2]}/>
        </div>
        </>
    )
}



