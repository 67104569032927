import React from 'react';

export default function(props){
    return(
        <>
        {props.true?
                <div className="outerSelector">
                {props.children}
                </div>
        :
        <>
        {props.children}
        </>
        }

        </>
    )
}



