import { Link } from '@reach/router';
import React from 'react';
import MobileNavBarPlain from '../generalComponents/MobileNavBarPlain';
import Button from '../SvgElements/Button';
import CheckMark from '../SvgElements/CheckMark';
import TrackPackageIcon from '../SvgElements/TrackPackageIcon';

export default function(props){
    const popUpQuantity=(e)=>{
        // console.log("test 1",e.target.id)
        e.stopPropagation()
        e.preventDefault()
        let neoModal={...props.userPack.modal}
        neoModal.open=true
        neoModal.type="quantity"
        neoModal.productId="1"
        props.userPack.methods.stateHandling('modal',neoModal)
    }
    return(
        <>
        <MobileNavBarPlain title="Shop"/>

        <div className="alignCenter">
        <div className="mainBody alignCenter r5 l5">
        <div className="productCard">
            <Link to="1">
            <div className="productImage">

            </div>
            <div className="row alignCenter t4">
                <div className="body1">BURN PM</div>
            </div>
            <div className="row alignCenter t4">
                <div className="half" style={{minWidth:""}}><div className="caption3 tachar">$49.99</div><div className="body1 red1">$34.99</div></div>
            </div>
            <div className="row alignCenter t4">
            <Button text="BUY NOW" style="3" action={popUpQuantity}/>
            </div>
            
            </Link>
        </div>
       </div>
        </div>
        </>
    )
}



