
import React from 'react';

export default function(){
    return(
        <>
        <svg width="25" height="19" viewBox="0 0 25 19" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M23.4432 13.0799H7.63344L8.42724 11.7715L21.6167 11.7522C22.0627 11.7522 22.445 11.4943 22.5246 11.1377L24.3512 2.86406C24.399 2.64707 24.3273 2.42363 24.1521 2.25391C24.0655 2.17038 23.9574 2.10309 23.8355 2.05672C23.7136 2.01034 23.5806 1.98599 23.4459 1.98535L6.66706 1.94024L6.5237 1.39453C6.43343 1.04648 6.04582 0.792969 5.60511 0.792969H1.50333C1.25478 0.792969 1.01641 0.872871 0.840653 1.0151C0.664899 1.15733 0.566162 1.35023 0.566162 1.55137C0.566162 1.75251 0.664899 1.94541 0.840653 2.08764C1.01641 2.22986 1.25478 2.30977 1.50333 2.30977H4.84582L5.47237 4.72031L7.01485 10.7639L5.02901 13.3871C4.92588 13.4998 4.86376 13.6335 4.84969 13.7732C4.83561 13.913 4.87014 14.0531 4.94936 14.1777C5.10865 14.4334 5.42989 14.5945 5.78564 14.5945H7.4529C7.09747 14.9766 6.90548 15.4419 6.906 15.9201C6.906 17.1361 8.12724 18.1244 9.6299 18.1244C11.1326 18.1244 12.3538 17.1361 12.3538 15.9201C12.3538 15.441 12.1573 14.9748 11.8069 14.5945H16.0839C15.7285 14.9766 15.5365 15.4419 15.537 15.9201C15.537 17.1361 16.7582 18.1244 18.2609 18.1244C19.7636 18.1244 20.9848 17.1361 20.9848 15.9201C20.9848 15.441 20.7883 14.9748 20.4379 14.5945H23.4459C23.9609 14.5945 24.383 14.2551 24.383 13.8361C24.3815 13.6352 24.2818 13.4429 24.1057 13.3013C23.9297 13.1596 23.6915 13.08 23.4432 13.0799V13.0799ZM7.05733 3.43555L22.3441 3.47637L20.8467 10.2611L8.83875 10.2783L7.05733 3.43555ZM9.6299 16.599C9.16795 16.599 8.79096 16.294 8.79096 15.9201C8.79096 15.5463 9.16795 15.2412 9.6299 15.2412C10.0918 15.2412 10.4688 15.5463 10.4688 15.9201C10.4688 16.1002 10.3805 16.2729 10.2231 16.4002C10.0658 16.5275 9.8524 16.599 9.6299 16.599V16.599ZM18.2609 16.599C17.7989 16.599 17.422 16.294 17.422 15.9201C17.422 15.5463 17.7989 15.2412 18.2609 15.2412C18.7228 15.2412 19.0998 15.5463 19.0998 15.9201C19.0998 16.1002 19.0114 16.2729 18.8541 16.4002C18.6968 16.5275 18.4834 16.599 18.2609 16.599V16.599Z" fill="#D8001C"/>
</svg>
        </>
    )
}



