import React, { Component } from 'react';
import {CardElement} from '@stripe/react-stripe-js';


const CardSection=()=>{
  return(
  <div>
      <div
        style={{
          maxWidth: "500px",
          margin: "0 auto",
        }}
      >
        <form
          style={{
            display: "block",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <CardElement
              className="card"
              options={{
                style: {
                  base: {
                    backgroundColor: "white"
                  } 
                },
              }}
            />
            {/* <button
              className="pay-button"
              disabled={isPaymentLoading}
            >
              {isPaymentLoading ? "Loading..." : "Pay"}
            </button> */}
          </div>
        </form>
      </div>
    </div>
  )}

  class CardSetupForm extends Component {
    state = {  }

    constructor(props)
    {
      super(props)
      props.userPack.methods.stateHandling('stripeEls',props.elements)
      props.userPack.methods.stateHandling('stripeObj',props.stripe)
      console.log(props.elements)
      // myEl[0].addEventListener('click',this.handleSubmit)
    }

    handleSubmit = async (event) => {
      // We don't want to let default form submission happen here,
      // which would refresh the page.
      event.preventDefault();
  
      if (!this.props.stripe || !this.props.elements) {
        // Stripe.js has not yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        return;
      }
      const cardElement = this.props.elements.getElement('card');
  
      // From here we can call createPaymentMethod to create a PaymentMethod
      // See our createPaymentMethod documentation for more:
      // https://stripe.com/docs/stripe-js/reference#stripe-create-payment-method
      let paymentIntent
      let error=null
      // paymentIntent= (await fetch(
      //   `https://stripe.com/v1/setup_intents`
      // ).then(res => res.json())).client_secret;
      // console.log(paymentIntent);
      let setUpIntent
      await this.props.userPack.axiosPack.axios.post(
        this.props.userPack.axiosPack.baseUrl+'createPaymentIntent/',{customerId:'cus_IhSULRqtmrjRjv'}).then(res=>{
          setUpIntent= res.data
          console.log(res,"boboos")})
    
      await this.props.stripe
        .createPaymentMethod({
          type:'card',
          card: cardElement,
        })
        .then(({paymentMethod}) => {
          console.log('Received Stripe PaymentMethod:', paymentMethod);
        }).catch(err=>{console.log(err);error=err.message})
         this.props.stripe.confirmCardSetup(setUpIntent.client_secret, {
          payment_method: {
            card: cardElement,
          },
        }).then(res=>{console.log(res,"cool finished")})
      }
    render() { 
      return ( 
            <form onSubmit={this.handleSubmit}>
      <CardSection />
    </form>
       );
    }
  }
   
  export default CardSetupForm;
// export default function CardSetupForm(props) {
//   const stripe = useStripe();
//   const elements = useElements();
//   const asyncRef= React.useRef()
//   const asyncHandler = async (event)=>{

//   }
//   const handleSubmit = async (event) => {
//     // We don't want to let default form submission happen here,
//     // which would refresh the page.
//     event.preventDefault();

//     if (!stripe || !elements) {
//       // Stripe.js has not yet loaded.
//       // Make sure to disable form submission until Stripe.js has loaded.
//       return;
//     }
//     const cardElement = elements.getElement('card');

//     // From here we can call createPaymentMethod to create a PaymentMethod
//     // See our createPaymentMethod documentation for more:
//     // https://stripe.com/docs/stripe-js/reference#stripe-create-payment-method
//     let paymentIntent
//     let error=null
//     // paymentIntent= (await fetch(
//     //   `https://stripe.com/v1/setup_intents`
//     // ).then(res => res.json())).client_secret;
//     // console.log(paymentIntent);
//     await props.stripe
//       .createToken({
//         card: cardElement,
//       })
//       .then(({paymentMethod}) => {
//         console.log('Received Stripe PaymentMethod:', paymentMethod);
//       }).catch(err=>{console.log(err);error=err.message})
//        stripe.confirmCardSetup(paymentIntent, {
//         payment_method: {
//           card: cardElement,
//         },
//       }).then(res=>{console.log(res,"cool finished")})

//   };

//   return (
//     <form onSubmit={handleSubmit}>
//       <CardSection />
//       <button disabled={!stripe}>Save Card</button>
//     </form>
//   );
// }
