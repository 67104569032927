import { Link } from '@reach/router';
import React from 'react';

export default function(){
    return(
        <>
        <Link to="../">
        <svg className="centerX" width="12" height="24" viewBox="0 0 12 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9.89747 23.0834C9.66093 23.0842 9.42721 23.032 9.2135 22.9306C8.99978 22.8292 8.81149 22.6812 8.66247 22.4975L1.01497 12.9976C0.782095 12.7142 0.654785 12.3589 0.654785 11.9921C0.654785 11.6254 0.782095 11.27 1.01497 10.9867L8.93164 1.48672C9.20039 1.16337 9.58658 0.960035 10.0053 0.921434C10.4239 0.882833 10.8408 1.01213 11.1641 1.28088C11.4875 1.54964 11.6908 1.93583 11.7294 2.3545C11.768 2.77318 11.6387 3.19004 11.37 3.51338L4.29247 12.0001L11.1325 20.4867C11.3261 20.7191 11.4491 21.0021 11.4869 21.3023C11.5247 21.6024 11.4757 21.907 11.3458 22.1802C11.2159 22.4534 11.0104 22.6836 10.7538 22.8437C10.4971 23.0037 10.1999 23.0869 9.89747 23.0834Z" fill="black"/>
</svg></Link>
        </>
    )
}



