import React from 'react';

export default function(props){
    return(
        <>
        <svg onClick={props.action} width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M20.3865 2.45625L18.6755 0.75L10.6089 8.79375L2.54238 0.75L0.831299 2.45625L8.89784 10.5L0.831299 18.5438L2.54238 20.25L10.6089 12.2062L18.6755 20.25L20.3865 18.5438L12.32 10.5L20.3865 2.45625Z" fill="#1F1F1F"/>
</svg>

        </>
    )
}



