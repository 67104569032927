

import { Link } from '@reach/router';
import React from 'react';

export default function(){
    return(
        <>
        <Link className="button" to="1">
        <div className="col-md-12 col-sm-12 alignCenter t2 b2">
<div className="orderCard">
<div className="body1" style={{position:"absolute",width:"50%",left:"40%",top:"26px"}}>Juice Plus Garden Blend
    Rest of title</div>
    <div className="body1 bold" style={{position:"absolute",width:"50%",left:"40%",top:"78px"}}>$125.00</div>
                <div className="itemPicture"></div>
</div>

</div>

</Link>
        </>
    )
}



