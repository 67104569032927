import React from 'react';

export default function(props){
    const clickAway=(e)=>{
        return e.target.getAttribute('id')==="tronco"
    }
    const close=(e)=>{
        if(clickAway(e)){
            props.close()
        }
    }
    return(
        <>
         <div className="modalBg" id="tronco" onClick={close}>
                {props.children}
         </div>
        </>
    )
}