import { Link } from '@reach/router';
import React from 'react';
import BackArrow from '../SvgElements/BackArrow';
import Button from '../SvgElements/Button';
import RightArrow from '../SvgElements/RightArrow';
import Selector from './Selector';

const { promisify } = require('util')
const sleep = promisify(setTimeout)

function formatNumber(num) {
    if(num.length>6){
        const middle=num.substring(3,6)
        const latter=num.substring(6,10)
        const beg=num.substring(0,3)
        return ("("+beg+") "+middle+"-"+latter)
    }
    return(num)
  }

const EditAddressCard =(props)=>{

    return(
        <>
          <div className="editAddressCard" value={props.address.id} onClick={props.select}>
  <div className="half">
      <div className="flexStart">
      <div className="body1 blue1 button">Edit</div>
      </div>
      <div className="flexEnd">
      <svg value={props.address.id} onClick={props.popUpDelete} className="button" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M5.73438 1.95312H5.5625C5.65703 1.95312 5.73438 1.87578 5.73438 1.78125V1.95312H12.2656V1.78125C12.2656 1.87578 12.343 1.95312 12.4375 1.95312H12.2656V3.5H13.8125V1.78125C13.8125 1.02285 13.1959 0.40625 12.4375 0.40625H5.5625C4.8041 0.40625 4.1875 1.02285 4.1875 1.78125V3.5H5.73438V1.95312ZM16.5625 3.5H1.4375C1.05723 3.5 0.75 3.80723 0.75 4.1875V4.875C0.75 4.96953 0.827344 5.04688 0.921875 5.04688H2.21953L2.7502 16.2832C2.78457 17.0158 3.39043 17.5938 4.12305 17.5938H13.877C14.6117 17.5938 15.2154 17.018 15.2498 16.2832L15.7805 5.04688H17.0781C17.1727 5.04688 17.25 4.96953 17.25 4.875V4.1875C17.25 3.80723 16.9428 3.5 16.5625 3.5ZM13.7115 16.0469H4.28848L3.76855 5.04688H14.2314L13.7115 16.0469Z" fill="#767676"/>
</svg></div></div>

              <div className="wide t2">
                  <div className="body1">{props.address.address1}</div>
              </div>
              <div className="wide t1">
                  <div className="body1">{props.address.city}</div>
              </div><div className="wide t1">
                  <div className="body1">{props.address.state} {props.address.zipcode}</div>
              </div>
              <div className="wide t1">
                  <div className="body1">United States</div>
              </div>
              <div className="wide t1">
                  <div className="body1">({'5614184047'.substring(0,3)})xxxxxx{'5614184047'.substring(8,10)}</div>
              </div>

</div>
        </>
    )
}

// props.address.phoneNumber
// props.address.phoneNumber

export default function(props){
    const select=(e)=>{
        let value= e.target.value
        let target = e.target
        while (true) {
            if(target.getAttribute("value")){
                console.log(" bonao",1)
                break
                //
            }else{
                target=target.parentElement
            }
        }
        
        let neoUser = {...props.userPack.user}
        let neoShippingAddress
        neoUser.defaultAddressId=target.getAttribute('value')
        neoShippingAddress= neoUser.associatedAddresses.filter((c,index)=>(c.id==target.getAttribute('value')))[0]
        neoShippingAddress.useAccountName=true
        props.userPack.methods.stateHandling('shippingInfo',neoShippingAddress)
        props.userPack.methods.stateHandling('user',neoUser)
    }
    const popUpDelete=(e)=>{
        // console.log("test 1",e.target.id)
        try {
            e.stopPropagation()
            e.preventDefault()
        } catch (error) {
            
        }
        let value= e.target.value
        let target = e.target
        while (true) {
            if(target.getAttribute("value")){
                break
                //
            }else{
                target=target.parentElement
            }
        }
        console.log("mucho antes",e.target)
        let neoModal={...props.userPack.modal}
        neoModal.open=true
        neoModal.type="deleteConfirmation"
        neoModal.addressId=target.getAttribute("value")
        delete neoModal.cardId
        props.userPack.methods.stateHandling('modal',neoModal)
    }
    const [shippingInfo,setShippingInfo] = React.useState({fullName:'',useAccountName:true})
    const handleUpdateLocal=(e)=>{
        console.log(e.target.value)
        let neoShippingInfo={...shippingInfo}
        neoShippingInfo.fullName=e.target.value
        if(props.userPack.shippingInfo.useAccountName&&props.userPack.logged){
            return
        }else{
            setShippingInfo(neoShippingInfo)
            return
        }
        
    }
    const token = window.localStorage.getItem('token')
    props.userPack.methods.axios.defaults.headers.post['Authorization']="JWT "+token
    async function confirmAddress(){
        console.log("y qloq")
        props.userPack.methods.stateHandling("shippingAddress",true)
        let sendingAddress = {...props.userPack.shippingInfo}
        sendingAddress.id="6666666"
        if(props.userPack.logged){
            sendingAddress.customer={id:props.userPack.user.id}
            await props.userPack.methods.axios.post(props.userPack.methods.baseUrl+'api/associatedaddress/',sendingAddress).then(
                res=>{console.log(res,"la maldita respuesta")}
            )
            console.log("la maldita respuestica")
        }
        

        props.userPack.refs.routeCheckoutRef.current.click()
        sleep(500)
        props.userPack.methods.getUser()
    }
    const handleUpdate=(e)=>{
        let neoShippingInfo={...props.userPack.shippingInfo}
        if(e.target.name==="useAccountName"&&props.userPack.logged){
            if(!props.userPack.shippingInfo.useAccountName){
                neoShippingInfo.fullName=props.userPack.user.name
                neoShippingInfo.useAccountName=!props.userPack.shippingInfo.useAccountName
                props.userPack.methods.stateHandling('shippingInfo',neoShippingInfo)
                return
            }
            else{
                neoShippingInfo.useAccountName=!props.userPack.shippingInfo.useAccountName
                props.userPack.methods.stateHandling('shippingInfo',neoShippingInfo)
                return
            }
        }
        
        if(e.target.name==="phoneNumber"||e.target.name==="zipcode"){
            let neoVal=e.target.value.replace(/\D/g, '')
            if(neoShippingInfo[e.target.name]&&neoShippingInfo[e.target.name].length>neoVal){
                neoVal=neoVal.substring(0,neoVal.length-1)
            }
            neoShippingInfo[e.target.name]=neoVal
            props.userPack.methods.stateHandling('shippingInfo',neoShippingInfo)
            return
        }
        neoShippingInfo[e.target.name]=e.target.value
        console.log("qloq")
        props.userPack.methods.stateHandling('shippingInfo',neoShippingInfo)
    }
    return(
        <>
        <div className="navPlain">
            <BackArrow/>
            <div className="title2 alignCenter">Shipping info</div>
            <div></div>
        </div>
        <div className="alignCenter">
            <div className="mainBody" style={{marginBottom:"110px"}}>
            <div className="wide t3 flexStart">
                {props.userPack.logged?
                <>
                                        <div className="flexStart">
                        <label className="pure-material-checkbox">
                            <input name="useAccountName" onChange={handleUpdate} type="checkbox" style={{zIndex:"90"}} defaultChecked={props.userPack.shippingInfo.useAccountName}/>
                            <span style={{zIndex:"-1"}}></span>
                            Use account name
                            </label>
                            </div>
                </>
                :null}

{/* <div className="material-textfield">
      <input onChange={handleUpdate} placeholder=" " type="text"/>
      <label>Label</label>
  </div> */}
                            </div>
                <div className="wide" style={{marginTop:"20px"}}>
                <form>

	<fieldset className="material">
		<input name="fullName" value={props.userPack.shippingInfo.fullName} onChange={handleUpdate} type="text"  autoComplete="off" required />
		<hr/>
		<label>Full name</label>
	</fieldset>


	<fieldset className="material">
		<input name="address1" value={props.userPack.shippingInfo.address1} onChange={handleUpdate} type="text"  autoComplete="off" required />
		<hr/>
		<label>Address 1</label>
	</fieldset>


	<fieldset className="material">
		<input name="address2" value={props.userPack.shippingInfo.address2} onChange={handleUpdate} type="text"  autoComplete="off" required />
		<hr/>
		<label>Address 2</label>
	</fieldset>
        <div className="half">
        <fieldset className="material">
		<input name="city" value={props.userPack.shippingInfo.city} onChange={handleUpdate} type="text"  autoComplete="off" required />
		<hr/>
		<label>City</label>
	</fieldset>
    <fieldset className="material">
		<input name="state" value={props.userPack.shippingInfo.state} onChange={handleUpdate} type="text"  autoComplete="off" required />
		<hr/>
		<label>State</label>
	</fieldset>
        </div>
        <div className="half">
        <fieldset className="material">
		<input name="zipcode" value={props.userPack.shippingInfo.zipcode} onChange={handleUpdate} type="number"  autoComplete="off" required />
		<hr/>
		<label>Zipcode</label>
	</fieldset>
    <fieldset className="material">
		<input name="phoneNumber" value={props.userPack.shippingInfo.phoneNumber?formatNumber(props.userPack.shippingInfo.phoneNumber):formatNumber("")} onChange={handleUpdate} type="tel"  autoComplete="off" required />
		<hr/>
		<label>Phone number</label>
	</fieldset>
        </div>
</form>
        </div>
        {props.userPack.logged?
        <>
        <div className="wide t3">
                  <div className="title2">My addresses</div>
              </div>
                <div className="wide t3" style={{display:"flex",flexWrap:"wrap",justifyContent:"center"}}>
            {props.userPack.user.associatedAddresses.map((add,index)=>(
                
                <Selector true={props.userPack.user.defaultAddressId==add.id}>
                <EditAddressCard select={select} address={add} key={index} popUpDelete={popUpDelete}/>
                </Selector>
            ))}
            
        </div>
        </>
        :null}


        </div>

        {/* // */}


        </div>
        
        {props.userPack.dimensions.width<769?<>
            <div className="wide t4" style={{position:"fixed",bottom:"0px",width:"100vw" }}>
                       <div className="wide alignCenter" style={{height:"70px",width:"100%", backgroundColor:"white"}}>
                        <Button text="Save address" action={confirmAddress}/>
                        </div>
                    </div>
        </>:null}

        {props.userPack.dimensions.width>769?<>
            <div className="wide alignCenter t4" style={{position:"fixed",bottom:"0px",width:"100vw"}}>
                       <div className="wide alignCenter" style={{height:"70px",width:"100%", backgroundColor:"white"}}>
                        <Button text="Save address" action={confirmAddress} style="4"/>
                        </div>
                    </div>
        </>:null}
        </>
    )
}



