import { Link,navigate } from '@reach/router';
import React from 'react';
import MobileNavBarPlain from '../generalComponents/MobileNavBarPlain';
import Button from '../SvgElements/Button';
import CheckMark from '../SvgElements/CheckMark';
import SocialTriade from '../SvgElements/SocialTriade';
import TrackPackageIcon from '../SvgElements/TrackPackageIcon';

export default function(props){
    let marginleft="0px"
    if (props.userPack.dimensions.width>945){
        console.log("soy el mejor")
        marginleft="6vw"
    }
    const popUpQuantity=(e)=>{
        // console.log("test 1",e.target.id)
        e.stopPropagation()
        e.preventDefault()
        let neoModal={...props.userPack.modal}
        neoModal.open=true
        neoModal.type="quantity"
        neoModal.productId="1"
        props.userPack.methods.stateHandling('modal',neoModal)
    }
    return(
        <>
        <MobileNavBarPlain title="Burn PM"/>

        <div className="alignCenter ">
        <div className="mainBodyStore alignCenter r5 l5 bootstrap-wrapper">
        <div className="singleProductCard container">
                <div className="row" style={{height:"auto",minHeight:"60vh"}}>
                    <div className="col-md-6 col-sm-12" style={{height:"440px",width:"100%"}}>
                        <div className="row alignCenter">
                            <div className="singleProductImage">

                            </div>
                        </div>
                        <div className="row flexStart t1"style={{paddingLeft:marginleft}}>
                                <div className="miniCard selected "></div><div className="miniCard"></div><div className="miniCard"></div>                            
                        </div>
            </div>
            <div className="col-md-6 col-sm-12">
            <div className="row flexStart t4">
                <div className="productTitle1">NIGHT BURN</div>
            </div>
            <div className="row flexStart t4">
                <div className="half alignCenter" ><div className="body1 tachar">$49.99</div><div style={{right:"10px"}} className="title1 red1">$34.99</div></div>
            </div>

            <div className="row flexStart descriptionText t4">
            BURN PM is a nighttime thermogenic
engineered to make you burn fat specifically while you sleep. BURN PM’s thermogenic formula includes powerful natural sleep enhancers to help you enjoy deep, Cortiosl-crushing
sleep.
            </div>
            <div className="row flexStart t4">
            
            <Button text="BUY NOW" style="3" action={popUpQuantity}/>
            </div>
            <div className="row flexStart t4">
                <div className="body1 grey1">Categories: </div><div style={{marginLeft:"5px"}} className="body1 red1">Fat Burner, Featured, Shop</div>
                </div>
                <div className="row flexStart t4" style={{padding:"0px !important"}}>
                <div className="col-sm-12 hidden-sm-down" style={{padding:"0px !important"}}>
            <div className="row flexStart" >
                <div className="title2 bold grey1">SHARE</div>
                <div className="l3"><SocialTriade/></div>
                
            </div>
            </div>
            </div>
            </div>

            </div>
            <div className="row">
        <div className="col-sm-12 hidden-md-up">
        <div className="half wide alignCenter t4">
                    <div className="productTitle1 l3 grey1">SHARE</div>
                    <SocialTriade/>
                </div>
        </div>
        </div>
            
        </div>
       </div>
       </div>
        </>
    )
}



