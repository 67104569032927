import React from 'react';
import MobileNavBarPlain from '../generalComponents/MobileNavBarPlain';
import OrderCard from './OrderCard';

export default function(){
    return(
        <>
        <MobileNavBarPlain title="Order history"/>
        <div className="mainBody">
        <div className="bootstrap-wrapper">
            <div className="container">
                <div className="row l2 b3 t2">
                        <div className="body1 grey1">Sort by </div><div className="l1 body1 black">Date</div>

                </div>
                <div className="row alignCenter">
                    <OrderCard/>
                    <OrderCard/>
                    <OrderCard/>               
                </div>
                </div>
            </div>
        </div>
        </>
    )
}



