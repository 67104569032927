import { Link } from '@reach/router';
import React from 'react';
import Button from './Button';

export default function(props){
    let marginRight
    let notificationAmount = 1

    return(
        <>
        <div className="relative alignCenter" id="cartIcon" style={{width:"57px",height:"60px"}}>
        <svg width="22" height="18" viewBox="0 0 31 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M29.1281 16.9005H9.68792L10.664 15.18L26.8821 15.1546C27.4305 15.1546 27.9006 14.8156 27.9985 14.3466L30.2445 3.46731C30.3033 3.18198 30.2151 2.88818 29.9997 2.665C29.8932 2.55516 29.7604 2.46668 29.6104 2.4057C29.4605 2.34473 29.297 2.3127 29.1313 2.31186L8.49964 2.25254L8.32336 1.53497C8.21237 1.07731 7.73575 0.743958 7.19384 0.743958H2.15017C1.84455 0.743958 1.55144 0.849024 1.33532 1.03604C1.11921 1.22306 0.997803 1.47672 0.997803 1.7412C0.997803 2.00569 1.11921 2.25934 1.33532 2.44636C1.55144 2.63338 1.84455 2.73845 2.15017 2.73845H6.26019L7.03061 5.90816L8.92729 13.855L6.48544 17.3044C6.35863 17.4525 6.28225 17.6284 6.26495 17.8121C6.24764 17.9959 6.29009 18.1801 6.38751 18.3441C6.58338 18.6802 6.97838 18.8921 7.41583 18.8921H9.46594C9.02888 19.3945 8.79281 20.0064 8.79345 20.6352C8.79345 22.2342 10.2951 23.5337 12.1428 23.5337C13.9905 23.5337 15.4922 22.2342 15.4922 20.6352C15.4922 20.0052 15.2506 19.3922 14.8197 18.8921H20.0789C19.6418 19.3945 19.4057 20.0064 19.4064 20.6352C19.4064 22.2342 20.908 23.5337 22.7557 23.5337C24.6035 23.5337 26.1051 22.2342 26.1051 20.6352C26.1051 20.0052 25.8636 19.3922 25.4326 18.8921H29.1313C29.7646 18.8921 30.2837 18.4458 30.2837 17.8949C30.2818 17.6307 30.1592 17.3778 29.9427 17.1915C29.7262 17.0052 29.4334 16.9006 29.1281 16.9005ZM8.97953 4.21878L27.7766 4.27245L25.9354 13.194L11.17 13.2166L8.97953 4.21878ZM12.1428 21.5279C11.5748 21.5279 11.1112 21.1267 11.1112 20.6352C11.1112 20.1436 11.5748 19.7425 12.1428 19.7425C12.7109 19.7425 13.1744 20.1436 13.1744 20.6352C13.1744 20.8719 13.0657 21.099 12.8723 21.2664C12.6788 21.4338 12.4164 21.5279 12.1428 21.5279ZM22.7557 21.5279C22.1877 21.5279 21.7242 21.1267 21.7242 20.6352C21.7242 20.1436 22.1877 19.7425 22.7557 19.7425C23.3238 19.7425 23.7873 20.1436 23.7873 20.6352C23.7873 20.8719 23.6786 21.099 23.4852 21.2664C23.2917 21.4338 23.0293 21.5279 22.7557 21.5279Z" fill="black"/>
</svg>

<div id="test" className="redCircle">
<div className="caption3 white">0</div>
</div>
<div className="cartHover">
    <div className="title3" style={{textAlign:"center"}}>Your cart is empty</div>
    <Button text="SHOP NOW!"/>
</div>
</div>

        </>
    )
}



